import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
declare var $: any;
import * as myGlobals from '../../globals';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonfunService } from 'src/app/services/commonfun.service';


@Component({
  selector: 'app-view-data-entry',
  templateUrl: './view-data-entry.component.html',
  styleUrls: ['./view-data-entry.component.css']
})
export class ViewDataEntryComponent implements OnInit {
  userData: any;
  companyData: any = [];
  userDataDd: any = [];
  public baseUrl: string = myGlobals.base_api_url;
  public imageSrc: any = '../../../assets/images/user.jfif';

  constructor(private apiService: ApiService, private spinner: NgxSpinnerService, public commonFun: CommonfunService) { }

  ngOnInit(): void {
    this.getAllEmployeeType();
    this.getSingleUser();
  }

  getAllEmployeeType() {
    let body = {
      status: ["Active"]
    }
    this.spinner.show();
    this.apiService.postMethod("/organisation/filter", body).subscribe(
      data => {
        this.spinner.hide();
        this.companyData = data.response.data.organisation;
      }, err => {
        this.spinner.hide();

      }
    )
  }
  getReportdata(companyId) {
    let body = {
      "status": ["Active"],
      "organisationId": [companyId]
    }
    this.spinner.show();
    this.apiService.postMethod("/user/filter", body).subscribe(
      data => {
        this.spinner.hide();
        this.userDataDd = data.response.data.user;
      },
      err => {
        this.spinner.hide();
      }
    )
  }
  getSingleUser() {
    this.spinner.show();
    this.apiService.getMethod('/user', sessionStorage.dataId).subscribe(
      data => {
        this.spinner.hide();
        this.userData = data.response.data.user;
        this.getReportdata(this.userData.organisationId);
        if (this.userData.profile != "") {
          this.imageSrc = this.baseUrl + this.userData.profile;
        }
        $("#companyDd").val(this.userData.organisationId).attr("selected", "selected");
        $("#userDd").val(this.userData.managerId).attr("selected", "selected");
        // $("#status").val(this.userData.status).attr("selected", "selected");
        $("#designation").val(this.userData.designation).attr("selected", "selected");
      },
      err => {
        this.spinner.hide();
      })
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ApiService } from '../../services/api.service';
declare var $: any;
import * as myGlobals from '../../globals';
import alertifyjs from 'alertifyjs';
import { NgxSpinnerService } from "ngx-spinner";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-edit-data-entry',
  templateUrl: './edit-data-entry.component.html',
  styleUrls: ['./edit-data-entry.component.css']
})
export class EditDataEntryComponent implements OnInit {

  registerForm: FormGroup;
  @ViewChild("fileInput", { static: false }) fileInput;
  userData: any;
  companyData: any = [];
  userDataDd: any = [];
  regisSubmitted: boolean = false;
  public baseUrl: string = myGlobals.base_api_url;
  public imageSrc: any = '../../../assets/images/user.jfif';
  imgUrl: string = "";
  constructor(public translate: TranslateService, private apiService: ApiService, private formBuilder: FormBuilder, private spinner: NgxSpinnerService,
    private router: Router) { }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      employeeName: ["", Validators.required],
      contactNumber: ["", [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      emailId: ["", [Validators.required, Validators.email]],
      employeeOf: ["", Validators.required],
      // designation: ["", Validators.required],
      reportTo: ["", Validators.required],
      // status: ["", Validators.required],
      image: [""],
    });
    this.getAllEmployeeType();
    this.getSingleUser();
  }
  get fr() {
    return this.registerForm.controls;
  }
  getAllEmployeeType() {
    let body = {
      status: ["Active"]
    }
    this.spinner.show();
    this.apiService.postMethod("/organisation/filter?pageno=no", body).subscribe(
      data => {
        this.spinner.hide();
        this.companyData = data.response.data.organisation;
      },
      err => {
        this.spinner.hide();

      }
    )
  }
  getReportdata(companyId) {
    let body = {
      "status": ["Active"],
      "organisationId": [companyId]
    }
    this.spinner.show();
    this.apiService.postMethod("/user/filter?pageno=no", body).subscribe(
      data => {
        this.spinner.hide();
        this.userDataDd = data.response.data.user;
      },
      err => {
        this.spinner.hide();
      }
    )
  }
  readURL(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = e => this.imageSrc = reader.result;
      reader.readAsDataURL(file);
    }
  }
  getSingleUser() {
    this.spinner.show();
    this.apiService.getMethod('/user', sessionStorage.dataId).subscribe(
      data => {
        this.spinner.hide();
        this.userData = data.response.data.user;
        if (this.userData.profile != "") {
          this.imageSrc = this.baseUrl + this.userData.profile;
          this.imgUrl = this.userData.profile;
        }
        this.getReportdata(this.userData.organisationId);
        this.registerForm.controls['employeeName'].setValue(this.userData.name);
        this.registerForm.controls['contactNumber'].setValue(this.userData.mobile);
        this.registerForm.controls['emailId'].setValue(this.userData.email);
        this.registerForm.controls['employeeOf'].setValue(this.userData.organisationId);
        // this.registerForm.controls['designation'].setValue(this.userData.designation);
        this.registerForm.controls['reportTo'].setValue(this.userData.managerId);
        // this.registerForm.controls['status'].setValue(this.userData.status);
      },
      err => {
        this.spinner.hide();

      })
  }
  editEmployee() {
    if (this.registerForm.invalid) {
      this.regisSubmitted = true;
      return;
    }
    this.regisSubmitted = false;

    let body = {
      "userName": this.userData.userName,
      "name": this.registerForm.value.employeeName,
      "fatherName": "",
      "spouseName": "",
      "gender": "",
      "mobile": this.registerForm.value.contactNumber,
      "email": this.registerForm.value.email,
      "dob": "2020-09-06T11:27:25.5917035+05:30",
      "address": {
        "stateCode": "",
        "districtCode": "",
        "villageCode": "",
        "zoneCode": "",
        "wardCode": "",
        "al1": "",
        "al2": "",
        "plotNo": "",
        "khataNo": "",
        "postalCode": "",
        "landmark": "",
        "location": {
          "type": "",
          "coordinates": null
        }
      },
      "profile": this.imgUrl,
      // "designation": this.registerForm.value.designation,
      "organisationId": this.registerForm.value.employeeOf,
      "type": "DEO",
      "managerId": this.registerForm.value.reportTo,
      // "status": this.registerForm.value.status
      "status": "Active"
    }
    let imageUrl = null;
    console.log(this.fileInput);
    if (this.fileInput.nativeElement.value == "") {
      imageUrl = "noImage";
      this.updateUser(body);
    } else {
      let fi = this.fileInput.nativeElement;
      let fileToUpload = fi.files[0];
      this.spinner.show();
      this.apiService.postFile('/common/docupload/profile', fileToUpload).subscribe(
        data => {
          this.spinner.hide();
          imageUrl = data.response.data.uri;
          body.profile = imageUrl;
          this.updateUser(body);
        },
        err => {
          this.spinner.hide();
        })
    }

  }
  updateUser(body) {
    this.spinner.show();
    this.apiService.putMethod2("/user", body).subscribe(
      data => {
        this.spinner.hide();
        // this.registerForm.reset();
        alertifyjs.notify(this.translate.instant('alrtSuccessfullyUpdated'), "success", 2, function () { })
        this.router.navigate(['/user_management/data-entry']);
      }, err => {
        this.spinner.hide();

      }
    )
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ApiService } from '../../services/api.service';
declare var $: any;
import alertifyjs from 'alertifyjs';
import { NgxSpinnerService } from "ngx-spinner";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-data-entry',
  templateUrl: './add-data-entry.component.html',
  styleUrls: ['./add-data-entry.component.css']
})
export class AddDataEntryComponent implements OnInit {
  registerForm: FormGroup;
  @ViewChild("fileInput", { static: false }) fileInput;
  regisSubmitted: boolean = false;
  companyData: any = [];
  userData: any = [];
  public imageSrc: any = '../../../assets/images/user.jfif';
  imageFlag: boolean = false;
  constructor(public translate: TranslateService, private formBuilder: FormBuilder, private apiService: ApiService, private spinner: NgxSpinnerService,
    private router: Router) { }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      employeeName: ["", Validators.required],
      contactNumber: ["", [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      emailId: ["", [Validators.required, Validators.email]],
      employeeOf: ["", Validators.required],
      // designation: ["", Validators.required],
      reportTo: ["", Validators.required],
      // status: ["", Validators.required],
      image: [""],
    });
    this.getAllEmployeeType();
  }
  get fr() {
    return this.registerForm.controls;
  }
  getAllEmployeeType() {
    let body = {
      status: ["Active"]
    }
    this.spinner.show();
    this.apiService.postMethod("/organisation/filter", body).subscribe(
      data => {
        this.spinner.hide();
        this.companyData = data.response.data.organisation;
      }, err => {
        this.spinner.hide();
      }
    )
  }
  getReportdata(companyId) {
    let body = {
      "status": ["Active"],
      "organisationId": [companyId],
      type: ["PM"]
    }
    this.spinner.show();
    this.apiService.postMethod("/user/filter", body).subscribe(
      data => {
        this.spinner.hide();
        this.userData = data.response.data.user;
      }, err => {
        this.spinner.hide();
      }
    )
  }
  readURL(event: any): void {
    if (event.target.files && event.target.files[0]) {
      this.imageFlag = true;
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = e => this.imageSrc = reader.result;
      reader.readAsDataURL(file);
    } else {
      this.imageFlag = false;
    }
  }
  addEmployee() {
    if (this.registerForm.invalid) {
      alertifyjs.notify(this.translate.instant('alrtPlzFillAllFld'), "error", 5, function () { });
      this.regisSubmitted = true;
      return;
    }
    this.regisSubmitted = false;
    let imageUrl = null;
    let body = {
      "name": this.registerForm.value.employeeName,
      "fatherName": "",
      "spouseName": "",
      "gender": "",
      "mobile": this.registerForm.value.contactNumber,
      "email": this.registerForm.value.emailId,
      "dob": "2020-09-06T11:27:25.5917035+05:30",
      "address": {
        "stateCode": "",
        "districtCode": "",
        "villageCode": "",
        "zoneCode": "",
        "wardCode": "",
        "al1": "",
        "al2": "",
        "plotNo": "",
        "khataNo": "",
        "postalCode": "",
        "landmark": "",
        "location": {
          "type": "",
          "coordinates": null
        }
      },
      "profile": "",
      // "designation": this.registerForm.value.designation,
      "organisationId": this.registerForm.value.employeeOf,
      "type": "DEO",
      "managerId": this.registerForm.value.reportTo,
      // "status": this.registerForm.value.status
      "status": "Active"
    }
    if (!this.imageFlag) {
      body.profile = "";
      this.saveTc(body);
    } else {
      let fi = this.fileInput.nativeElement;
      let fileToUpload = fi.files[0];
      this.spinner.show();
      this.apiService.postFile('/common/docupload/profile', fileToUpload).subscribe(
        data => {
          this.spinner.hide();
          imageUrl = data.response.data.uri;
          body.profile = imageUrl;
          this.saveTc(body);
        }, err => {
          this.spinner.hide();

        })
    }
  }
  saveTc(body) {
    this.spinner.show();
    this.apiService.postMethod("/user", body).subscribe(
      data => {
        this.spinner.hide();
        this.registerForm.reset();
        alertifyjs.notify(this.translate.instant('alrtSuccessfullyAdded'), "success", 2, function () { });
        this.router.navigate(['/user_management/data-entry']);
      },
      err => {
        this.spinner.hide();

      }
    )
  }
}

import { Component, OnInit } from '@angular/core';
declare var $: any;
import { Router } from "@angular/router";
import * as myGlobals from '../../globals';

@Component({
  selector: 'app-arv',
  templateUrl: './arv.component.html',
  styleUrls: ['./arv.component.css']
})
export class ArvComponent implements OnInit {
  public baseUrl = myGlobals.base_api_url;
  constructor(private router: Router,) { }

  ngOnInit(): void {
  }
  openAddModel(){
    $("#addRecord").modal("show");
  }
}

<div class="app-container app-theme-white body-tabs-shadow fixed-header fixed-sidebar">
    <div class="app-main">
        <!-- User profile div start from here-->
        <div class="app-main__outer">
            <div class="app-main__inner">
                <div class="app-page-title">
                    <div class="page-title-wrapper">
                        <div class="page-title-heading">
                            <div class="page-title-icon">
                                <i class="pe-7s-medal icon-gradient bg-tempting-azure"></i>
                            </div>
                            <div>Settings</div>
                        </div>
                        <div class="page-title-actions">
                            <a (click)="editbtn()"
                                class="btn-wide mb-2 mr-2 btn-icon btn-icon-right btn-shadow btn-pill btn btn-outline-success" 
                                >Edit<i class="pe-7s-plus btn-icon-wrapper">
                                </i></a>
                        </div>
                    </div>
                </div>
                <form [formGroup]="settingForm">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="main-card mb-3 card">
                                <div class="card-body">
                                    <!--<h5 class="card-title">Property Details</h5>-->
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label><strong>Name<span
                                                            class='red'>*</span></strong></label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="btn btn-secondary">
                                                            <i class="fa fa-building"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" formControlName="configName" class="form-control">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label><strong>Address<span
                                                            class='red'>*</span></strong></label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="btn btn-secondary">
                                                            <i class="fa fa-building"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" formControlName="configAddress" class="form-control">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label><strong>Contact Us<span
                                                            class='red'>*</span></strong></label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="btn btn-secondary">
                                                            <i class="fa fa-anchor"></i>
                                                        </span>
                                                    </div>
                                                    <input formControlName="contactUs" class="form-control">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label><strong> Send Email<span
                                                            class='red'>*</span></strong></label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="btn btn-secondary">
                                                            <i class="fa fa-anchor"></i>
                                                        </span>
                                                    </div>
                                                    <input formControlName="sendEmail" class="form-control">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label><strong>Mobile<span
                                                            class='red'>*</span></strong></label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="btn btn-secondary">
                                                            <i class="fa fa-id-badge"></i>
                                                        </span>
                                                    </div>
                                                    <input formControlName="mobileNo" class="form-control">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label><strong>Phone No<span
                                                            class='red'>*</span></strong></label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="btn btn-secondary">
                                                            <i class="fa fa-thumbs-up"></i>
                                                        </span>
                                                    </div>
                                                    <input formControlName="phoneNo" class="form-control">
                                                </div>
                                            </div>
                                        </div>
                                    </div>   
                                </div>
                            </div>
                        </div>
                    </div>
                        <div class="main-card mb-3 card">
                                <div class="card-body">
                                    <h5 class="card-title"></h5>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="row">
                                                <div class="col-lg-12">
                                                <table>
                                                    <tr>
                                                        <td>Logo</td>
                                                        <td><input style="margin-bottom: 0.5rem;" type="file" formControlName="logo" 
                                                            (change)="readURL($event)"></td>
                                                        <td><img src="{{baseUrl+configurationList.logo}}" alt width="300px" height="250px"></td> 
                                                    </tr>
                                                    <tr>
                                                        <td>LogowithName</td>
                                                        <td><input style="margin-bottom: 0.5rem;" type="file" formControlName="logowithName" 
                                                            (change)="logowithnameURL($event)"></td>
                                                        <td><img src="{{baseUrl+configurationList.logoWithName}}" alt width="300px" height="70px"></td>   
                                                    </tr>
                                                    <tr>
                                                        <td>WaterMark</td>
                                                        <td><input style="margin-bottom: 0.5rem;" type="file" formControlName="waterMark" 
                                                            (change)="watermarkURL($event)"></td>
                                                        <td><img src="{{baseUrl+configurationList.waterMark}}" alt width="300px" height="250px"></td>
                                                    </tr>
                                                </table> 
                                                </div>  
                                            </div>
                                        </div>
                                    </div>   
                                </div>
                            </div>
                            <div class="main-card mb-3 card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label><strong>Copyright<span
                                                            class='red'>*</span></strong></label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="btn btn-secondary">
                                                            <i class="fa fa-building"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" formControlName="copyright" class="form-control">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label><strong>Powered By<span
                                                            class='red'>*</span></strong></label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="btn btn-secondary">
                                                            <i class="fa fa-anchor"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" formControlName="poweredBy" class="form-control">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label><strong>Rights<span
                                                            class='red'>*</span></strong></label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="btn btn-secondary">
                                                            <i class="fa fa-id-badge"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" formControlName="rights" class="form-control">
                                                </div>
                                            </div>
                                        </div>
                                    </div>   
                                </div>
                            </div>
                            <div class="main-card mb-3 card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label><strong>State<span
                                                            class='red'>*</span></strong></label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="btn btn-secondary">
                                                            <i class="fa fa-anchor"></i>
                                                        </span>
                                                    </div>
                                                    <select type="text" formControlName="configState" class="form-control" 
                                                    (change)="getDistrict($event.target.value)" id="statedrop">
                                                        <option>--Select State--</option>
                                                        <option *ngFor="let data of state" value="{{data?.code}}" 
                                                      >{{data?.name}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label><strong>District<span
                                                    class='red'>*</span></strong></label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="btn btn-secondary">
                                                            <i class="fa fa-anchor"></i>
                                                        </span>
                                                    </div>
                                                    <select type="text" formControlName="configDistrict" class="form-control"
                                                    (change)="getVillage($event.target.value)" id="districtdrop">
                                                        <option>--Select District--</option>
                                                        <option value="{{data?.code}}" 
                                                        *ngFor="let data of district">{{data?.name}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label><strong>City<span
                                                            class='red'>*</span></strong></label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="btn btn-secondary">
                                                            <i class="fa fa-anchor"></i>
                                                        </span>
                                                    </div>
                                                    <select type="text" formControlName="configCity" class="form-control" 
                                                     id="villagedrop" (change)="villageList()">
                                                        <option>--Select City--</option>
                                                        <option value="{{data?.code}}" 
                                                        *ngFor="let data of village">{{data?.name}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label><strong>Municipality<span
                                                    class='red'>*</span></strong></label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="btn btn-secondary">
                                                            <i class="fa fa-anchor"></i>
                                                        </span>
                                                    </div>
                                                    <select type="text" formControlName="configMunicipality" class="form-control" 
                                                    id="municipaldrop" (change)="municipal()">
                                                        <option>--Select Municipality--</option>
                                                        <option value="{{data?.uniqueId}}" 
                                                        *ngFor="let data of municipalData">{{data.name}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label><strong>Longitude<span
                                                            class='red'>*</span></strong></label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="btn btn-secondary">
                                                            <i class="fa fa-id-badge"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" formControlName="longitude" class="form-control">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label><strong>Latitude<span
                                                            class='red'>*</span></strong></label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="btn btn-secondary">
                                                            <i class="fa fa-thumbs-up"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" formControlName="latitude" class="form-control">
                                                </div>
                                            </div>
                                        </div>
                                    </div>   
                                </div>
                            </div>
                            <div class="main-card mb-3 card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label><strong>Doa Start Year<span
                                                            class='red'>*</span></strong></label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="btn btn-secondary">
                                                            <i class="fa fa-id-badge"></i>
                                                        </span>
                                                    </div>
                                                    <input type="date" formControlName="doaStrtYear" class="form-control">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label><strong>App Version<span
                                                            class='red'>*</span></strong></label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="btn btn-secondary">
                                                            <i class="fa fa-thumbs-up"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" formControlName="appVersion" class="form-control">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label><strong>Yoa<span
                                                            class='red'>*</span></strong></label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="btn btn-secondary">
                                                            <i class="fa fa-anchor"></i>
                                                        </span>
                                                    </div>
                                                    <select formControlName="configYoA" class="form-control" id="yeardrop" (change)="year()">
                                                        <option value="{{data?.uniqueId}}" 
                                                        *ngFor="let data of financialYear">{{data?.name}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label><strong>Captcha<span
                                                    class='red'>*</span></strong></label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="btn btn-secondary">
                                                            <i class="fa fa-anchor"></i>
                                                        </span>
                                                    </div>
                                                    <select formControlName="captcha" class="form-control">
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">No</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label><strong>Legacy<span
                                                            class='red'>*</span></strong></label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="btn btn-secondary">
                                                            <i class="fa fa-id-badge"></i>
                                                        </span>
                                                    </div>
                                                    <select formControlName="configLegacy" class="form-control">
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">No</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label><strong>Mobile Tower<span
                                                            class='red'>*</span></strong></label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="btn btn-secondary">
                                                            <i class="fa fa-thumbs-up"></i>
                                                        </span>
                                                    </div>
                                                    <select formControlName="configMobileTower" class="form-control">
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">No</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label><strong>Reports<span
                                                            class='red'>*</span></strong></label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="btn btn-secondary">
                                                            <i class="fa fa-id-badge"></i>
                                                        </span>
                                                    </div>
                                                    <select formControlName="configReports" class="form-control">
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">No</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label><strong>Operation<span
                                                            class='red'>*</span></strong></label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="btn btn-secondary">
                                                            <i class="fa fa-thumbs-up"></i>
                                                        </span>
                                                    </div>
                                                    <select formControlName="configOperation" class="form-control">
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">No</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label><strong>Shop Rent<span
                                                            class='red'>*</span></strong></label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="btn btn-secondary">
                                                            <i class="fa fa-id-badge"></i>
                                                        </span>
                                                    </div>
                                                    <select formControlName="configShopRent" class="form-control">
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">No</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label><strong>Trade License<span
                                                            class='red'>*</span></strong></label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="btn btn-secondary">
                                                            <i class="fa fa-thumbs-up"></i>
                                                        </span>
                                                    </div>
                                                    <select formControlName="configTradeLicense" class="form-control">
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">No</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label><strong>TL Part 2<span
                                                            class='red'>*</span></strong></label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="btn btn-secondary">
                                                            <i class="fa fa-id-badge"></i>
                                                        </span>
                                                    </div>
                                                    <select formControlName="tlPart2" class="form-control">
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">No</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label><strong>Water Bill<span
                                                            class='red'>*</span></strong></label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="btn btn-secondary">
                                                            <i class="fa fa-thumbs-up"></i>
                                                        </span>
                                                    </div>
                                                    <select formControlName="configWaterBill" class="form-control">
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">No</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                                <div class="row">
                                        <div class="col-lg-4">
                                            <button type="submit" class="btn btn-secondary" (click)="ProductConfig()">Submit</button>
                                        </div>
                                    </div>
                </form>
            </div>
        </div>
        <!-- User profile div start from here-->
    </div>
</div>

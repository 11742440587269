<div class="app-container app-theme-white body-tabs-shadow fixed-header fixed-sidebar">
    <div class="app-main">
        <!-- User profile div start from here-->
        <div class="app-main__outer">
            <div class="app-main__inner">
                <div class="app-page-title">
                    <div class="page-title-wrapper">
                        <div class="page-title-heading">
                            <div class="page-title-icon">
                                <i class="pe-7s-note icon-gradient bg-premium-dark"></i>
                            </div>
                            <div>Edit {{'dataEntry' | translate}}</div>
                        </div>
                        <div class="page-title-actions">
                            <a class="btn-wide mb-2 mr-2 btn-icon btn-icon-right btn-shadow btn-pill btn btn-focus"
                                routerLink="/user_management/data-entry">{{'TCEditBack' | translate}}<i
                                    class="pe-7s-angle-left btn-icon-wrapper"> </i></a>
                        </div>
                    </div>
                </div>
                <form [formGroup]="registerForm" (ngSubmit)="editEmployee()">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="main-card mb-3 card">
                                <div class="card-body">
                                    <h5 class="card-title">{{'TCEditEmpMstr' | translate}}</h5><br>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label><strong>{{'TCEditEmpName' | translate}}<span
                                                            class='red'>*</span></strong></label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="btn btn-secondary">
                                                            <i class="fa fa-user"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control"
                                                        formControlName="employeeName"
                                                        [ngClass]="{'red-border-class': regisSubmitted && fr.employeeName.errors}">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label><strong>{{'TCEditContact' | translate}}<span
                                                            class='red'>*</span></strong></label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="btn btn-secondary">
                                                            <i class="fa fa-phone"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control"
                                                        formControlName="contactNumber"
                                                        [ngClass]="{'red-border-class': regisSubmitted && fr.contactNumber.errors}">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label><strong>{{'TCEditEmail' | translate}}<span
                                                            class='red'>*</span></strong></label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="btn btn-secondary">
                                                            <i class="fa fa-envelope"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control" formControlName="emailId"
                                                        [ngClass]="{'red-border-class': regisSubmitted && fr.emailId.errors}">
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="col-md-6">
                                            <div class="form-group">
                                                <label><strong>{{'TCEditStatus' | translate}}<span
                                                            class='red'>*</span></strong></label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="btn btn-secondary">
                                                            <i class="fa fa-eye"></i>
                                                        </span>
                                                    </div>
                                                    <select class="form-control" formControlName="status"
                                                        [ngClass]="{'red-border-class': regisSubmitted && fr.status.errors}">
                                                        <option value="">Select Status</option>
                                                        <option>Active</option>
                                                        <option>Deactive</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div> -->
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label><strong>{{'TCEditEmpOf' | translate}}<span
                                                            class='red'>*</span></strong></label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="btn btn-secondary">
                                                            <i class="fa fa-building"></i>
                                                        </span>
                                                    </div>
                                                    <select class="form-control" formControlName="employeeOf"
                                                        [ngClass]="{'red-border-class': regisSubmitted && fr.employeeOf.errors}">
                                                        <option value="" disabled>Select</option>
                                                        <option value="{{data?.uniqueId}}"
                                                            *ngFor="let data of companyData"> {{data?.name}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label><strong>{{'TCEditReportTo' | translate}}<span
                                                            class='red'>*</span></strong></label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="btn btn-secondary">
                                                            <i class="fa fa-user-secret"></i>
                                                        </span>
                                                    </div>
                                                    <select class="form-control" formControlName="reportTo"
                                                        [ngClass]="{'red-border-class': regisSubmitted && fr.reportTo.errors}">
                                                        <option value="" disabled>Select</option>
                                                        <option value="{{data?.userName}}"
                                                            *ngFor="let data of userDataDd"> {{data?.name}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label><strong>Select Designation<span
                                                            class='red'>*</span></strong></label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="btn btn-secondary">
                                                            <i class="fa fa-id-badge"></i>
                                                        </span>
                                                    </div>
                                                    <select class="form-control" formControlName="designation"
                                                        [ngClass]="{'red-border-class': regisSubmitted && fr.designation.errors}">
                                                        <option value="" disabled>--Select Designation---</option>
                                                        <option value="1">Circle Officer</option>
                                                        <option value="2">Back Office</option>
                                                        <option value="3">Accounts</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="main-card mb-3 card">
                                <div class="card-body">
                                    <h5 class="card-title">{{'TCEditEmpPicture' | translate}}</h5><br>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span class="btn btn-secondary"><i class="fa fa-image"></i></span>
                                                </div>
                                                <input type="file" class="form-control" formControlName="image"
                                                    #fileInput (change)="readURL($event)"
                                                    [ngClass]="{'red-border-class': regisSubmitted && fr.image.errors}">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="input-group">
                                                <img [src]="imageSrc" style="margin-top: -14px;
                                    border: 2px solid gray;height: 100px;width: 100px;
                                    border-radius: 6px;">
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                    <hr>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <button class="mb-2 mr-2 btn btn-gradient-success">{{'TCEditBtn' |
                                                translate}}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- User profile div start from here-->
    </div>
</div>
<div class="app-drawer-overlay d-none animated fadeIn"></div>
import { DataSharingServiceService } from './service/data-sharing-service.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from './services/api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'LGF Property Tax';
  loginStatus: boolean = false;
  configuration: any;
  constructor(private dataSharingService: DataSharingServiceService, private router: Router,
    private apiService: ApiService, private spinner: NgxSpinnerService) {
    dataSharingService.configuration.subscribe(
      value => {
        if (!value.hasOwnProperty("productConfiguration")) {
          this.getConfiguration();
        }
      }
    )
    this.dataSharingService.userDetails.subscribe(value => {
      if (value) {
        this.dataSharingService.isUserLoggedIn.next(true);
      } else if (this.checkLoginStatus()) {
        if (sessionStorage.script == "false")
          this.loadScript()
        this.loginStatus = true;
        // this.router.navigate(['/dashboard']);
        this.dataSharingService.isUserLoggedIn.next(true);
      } else {
        this.loginStatus = false;
      }
    });

    this.dataSharingService.isUserLoggedIn.subscribe(value => {
      this.loginStatus = value;
    });
    // this.loadScript();

  }
  getConfiguration() {
    this.spinner.show();
    this.apiService.getMethod1("/product/configuration").subscribe(
      data => {
        this.spinner.hide();
        this.dataSharingService.configuration.next(data.response.data);
        this.configuration = data.response.data.productConfiguration;
        if (!this.checkLoginStatus()) {
          console.log("config ", this.configuration);
          let consLogin = sessionStorage.getItem('consLogin');
          if (!consLogin || consLogin != 'true')
          this.dataSharingService.backToHomePage(this.router,this.configuration)
            // switch (this.configuration.name) {
            //   case "Kochas Nagar Panchayat":
            //     this.router.navigate(['/home-page'])
            //     break;
            //   case "Silao Nagar Panchayat":
            //     this.router.navigate(['/home-page'])
            //     break;
            //   case "Aurangabad Nagar Parishad":
            //     this.router.navigate(['/aurangabad-home-page']);
            //     break;
            //   case "Bakhtiyarpur Nagar Parishad":
            //     this.router.navigate(['/bakhtiyarpur-home-page']);
            //     break;
            //     case "Ramnagar Nagar Parishad":
            //       this.router.navigate(['/ramnagar-home-page']);
            //       break;
            //       case "Munger Nagar Nigam":
            //         this.router.navigate(['/munger-home-page']);
            //         break;
            //         case "Bhagalpur Nagar Nigam":
            //           this.router.navigate(['/bhagalpur-home-page']);
            //           break;
            //           case "Bhilai Nagar Nigam":
            //             this.router.navigate(['/home-page']);
            //             break;
            //   default:
            //     this.router.navigate(['/home-page'])
            // }
          // if (this.configuration.name == "Kochas Nagar Panchayat" || this.configuration.name == "Silao Nagar Panchayat") {
          //   this.router.navigate(['/home-page'])
          // } else {
          //   this.router.navigate(['/aurangabad-home-page']);
          // }
        }
      },
      err => {
        this.spinner.hide();
      }
    )
  }
  checkLoginStatus() {
    console.log("look ", sessionStorage.getItem('isUserLoggedIn'));
    if (sessionStorage.getItem('isUserLoggedIn') == 'true') {
      return true;
    } else {
      return false;
    }
  }
  removejsfile(filename, filetype) {
    var targetelement = (filetype == "js") ? "script" : (filetype == "css") ? "link" : "none" //determine element type to create nodelist from
    var targetattr = (filetype == "js") ? "src" : (filetype == "css") ? "href" : "none" //determine corresponding attribute to test for

    var allsuspects = document.getElementsByTagName(targetelement)
    for (var i = allsuspects.length; i >= 0; i--) { //search backwards within nodelist for matching elements to remove
      if (allsuspects[i] && allsuspects[i].getAttribute(targetattr) != null && allsuspects[i].getAttribute(targetattr).indexOf(filename) != -1)
        allsuspects[i].parentNode.removeChild(allsuspects[i]) //remove element by calling parentNode.removeChild()
    }
  }
  public loadScript() {
    let body = <HTMLDivElement>document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = '../assets/assets/scripts/main.d810cf0ae7f39f28f336.js';
    // script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }
  public loadScript1(url) {
    // var isFound = false;
    // var scripts = document.getElementsByTagName("script")
    // for (var i = 0; i < scripts.length; ++i) {
    //   if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes("loader")) {
    //     isFound = true;
    //   }
    // }

    // if (!isFound) {
    var dynamicScripts = [url];

    for (var i = 0; i < dynamicScripts.length; i++) {
      let node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      // node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }

  }
}

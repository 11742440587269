import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-select-lang',
  templateUrl: './select-lang.component.html',
  styleUrls: ['./select-lang.component.css']
})
export class SelectLangComponent {
  selectedLang: string;
  public langArr: any = [];
  constructor(public translate: TranslateService) {
    // console.log("language ", sessionStorage.language);
    let language = translate.getLangs();
    this.langArr.push({ name: "English", val: language[0] });
    this.langArr.push({ name: "Hindi", val: language[1] });
    this.selectedLang = sessionStorage.language || 'English';
  }
  languageChange(val) {
    sessionStorage.setItem("language", val);
    this.translate.use(sessionStorage.language);
  }
}

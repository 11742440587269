import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as myGlobals from '../../globals';
@Component({
  selector: 'app-hdfc',
  templateUrl: './hdfc.component.html',
  styleUrls: ['./hdfc.component.css']
})
export class HdfcComponent implements OnInit {
  @ViewChild("fileInput", { static: false }) fileInput;
  public baseUrl = myGlobals.base_api_url;
  public configuration: boolean = true;
  settingForm: FormGroup;
  state: any=[];
  district: any=[];
  village: any=[];
  municipalData: any=[];
  financialYear: any=[];
  logo: any;
  logowithname: any;
  watermark: any;
  theFile: any = null;
  configurationList: any;
  stateName: any;
  districtName: any;
  villageName: any;
  municipalName: any;
  yearName: any;
  
  constructor(private formBuilder: FormBuilder, public apiService: ApiService, private spinner: NgxSpinnerService) { }
  
  ngOnInit(): void {
    this.settingForm = this.formBuilder.group({
      mid: [{value: "", disabled: true}, Validators.required],
      Workingkey: [{value: "", disabled: true}, Validators.required],
      accessCode: [{value: "", disabled: true}, Validators.required],
      baseurl: [{value: "", disabled: true}, Validators.required],
   
    
    })
    this.getState();
    this.getMunicipalType();
    this.getYearofAssess();
    this.filterConfiguration();
  }

editbtn(){
  this.settingForm.enable();
  }

ProductConfig(){
 let loggedInUser = JSON.parse(sessionStorage.loggedInObj);
  let loggedInUserName =loggedInUser.userName
  let body = {
     'baseUrl':this.settingForm.value.baseurl,
    'workingKey':this.settingForm.value.Workingkey,
     'accessCode':this.settingForm.value.accessCode,
    'merchantId':this.settingForm.value.mid,
    'isDefault':true,
    'status':'Active',
    "created" : {
      "by" : loggedInUserName
      }
    
  }
  
this.apiService.postMethod("/hdfc/paymentgateway", body).subscribe(
  data=>{
    this.filterConfiguration();
    alert("Submitted Successfully");
    this.settingForm.disable();
    console.log("product", data);
  }
)
}

getState() {
  let body = {
    "status": ["Active"]
  }
  this.spinner.show();
  this.apiService.postMethod("/state/filter?pageno=no", body).subscribe(
    data => {
      this.spinner.hide();
      console.log(data);
      this.state = data.response.data.data;
      this.getDistrict(this.state[0].code);
      console.log(this.state[0].code);
    },
    err => {
      this.spinner.hide();
    }
  )
}

getDistrict(stateId) {
  let body = {
    "status": ["Active"],
    "stateCodes": [stateId]
  }
  this.spinner.show();
  this.apiService.postMethod("/district/filter?pageno=no", body).subscribe(
    data => {
      this.spinner.hide();
      console.log(data);
      this.district = data.response.data.data;
      var e= document.getElementById("statedrop") as HTMLSelectElement;
      this.stateName= e.options[e.selectedIndex].text;
      console.log("stateName",this.stateName);
      this.getVillage(this.district[0].code);
    },
    err => {
      this.spinner.hide();
    }
  )
}

getVillage(districtId) {
  let body = {
    "status": ["Active"],
    "districtCodes": [districtId]
  }
  this.spinner.show();
  this.apiService.postMethod("/village/filter?pageno=no", body).subscribe(
    data => {
      this.spinner.hide();
      console.log(data);
      this.village = data.response.data.data;
      var e= document.getElementById("districtdrop") as HTMLSelectElement;
      this.districtName= e.options[e.selectedIndex].text;
      console.log("districtName", this.districtName);
      console.log("village",this.village);
    },
    err => {
      this.spinner.hide();
    }
  )
}

villageList(){
  var e= document.getElementById("villagedrop") as HTMLSelectElement;
  this.villageName= e.options[e.selectedIndex].text;
}

getMunicipalType() {
  let body = {
    "status": ["Active"]
  }
  this.spinner.show();
  this.apiService.postMethod("/municipalType/filter?pageno=no", body).subscribe(
    data => {
      this.spinner.hide();
      console.log(data);
      this.municipalData = data.response.data.municipalType;
    },
    err => {
      this.spinner.hide();
    }
  )
}

municipal(){
  var e= document.getElementById("municipaldrop") as HTMLSelectElement;
  this.municipalName= e.options[e.selectedIndex].text;
  console.log("Municipal", this.municipalName);
}

getYearofAssess() {
  let body = {
    "status": ["Active"]
  }
  this.spinner.show();
  this.apiService.postMethod("/financialYear/filter?pageno=no", body).subscribe(
    data => {
      this.spinner.hide();
      console.log(data);
      this.financialYear = data.response.data.financialYear;
    }
  )
}

year(){
  var e= document.getElementById("yeardrop") as HTMLSelectElement;
  this.yearName= e.options[e.selectedIndex].text;
}

readURL(event: any): void{
  if(event.target.files && event.target.files[0]){
    const file = event.target.files[0]
    const reader = new FileReader();
    reader.readAsDataURL(file)
    this.onFileChange(event)
  } 
}
onFileChange(event: any) {
  this.theFile = null
  const MaxSize = 5000000;
  if (event.target.files && event.target.files.length > 0) {
      if (event.target.files[0].size < MaxSize) {
          this.theFile = event.target.files[0];
          this.solutionImg(this.theFile)
      }
      else {
        alert("File size is big");
        event.target.value = null;
        //this.theFile.reset();
      }
  }
}
solutionImg(file) {
  this.spinner.show();
  this.apiService.postFile('/common/docupload/municipal', file).subscribe(
    data =>{
      this.spinner.hide();
      this.logo=data.response.data.uri;
      console.log("ddffgg",this.logo)
    }, err =>{
      this.spinner.hide()
      console.log(err)
    }
  )
}
logowithnameURL(event: any): void{
  if(event.target.files && event.target.files[0]){
    const file = event.target.files[0]
    const reader = new FileReader();
    reader.readAsDataURL(file)
    this.onFileClick(event)
  } 
}
onFileClick(event: any) {
  this.theFile = null
  const MaxSize = 5000000;
  if (event.target.files && event.target.files.length > 0) {
      if (event.target.files[0].size < MaxSize) {
          this.theFile = event.target.files[0];
          this.logoimage(this.theFile)
      }
      else {
        alert("File size is big");
        event.target.value = null;
        //this.theFile.reset();
      }
  }
}
logoimage(file) {
  this.spinner.show()
  this.apiService.postFile('/common/docupload/municipal', file).subscribe(
    data =>{
      this.spinner.hide()
      this.logowithname=data.response.data.uri
      console.log("ddffgg",this.logowithname)
    }, err =>{
      this.spinner.hide()
      console.log(err)
    }
  )
}

watermarkURL(event: any): void{
  if(event.target.files && event.target.files[0]){
    const file = event.target.files[0]
    const reader = new FileReader();
    reader.readAsDataURL(file)
    this.onClickChange(event)
  } 
}
onClickChange(event: any) {
  this.theFile = null
  const MaxSize = 5000000;
  if (event.target.files && event.target.files.length > 0) {    
      if (event.target.files[0].size < MaxSize) {
          this.theFile = event.target.files[0];
          this.watermarkimage(this.theFile)
      }
      else {
        alert("File size is big");
        event.target.value = null;
      }
  }
}
watermarkimage(file) {
  this.spinner.show()
  this.apiService.postFile('/common/docupload/municipal', file).subscribe(
    data =>{
      this.spinner.hide()
      this.watermark=data.response.data.uri
      console.log("ddffgg",this.watermark);
    }, err =>{
      this.spinner.hide();
      console.log(err)
    }
  )
}

filterConfiguration(){
    this.spinner.show();
    this.apiService.getMethod1("/hdfc/paymentgateway").subscribe(
      data => {
        this.spinner.hide();
        // console.log("product", this.configurationList.state.id);
        this.configurationList = data.response.data.hdfcPayment;
        console.log("filter",this.settingForm.controls['Workingkey'].setValue(this.configurationList.workingKey));

        this.settingForm.controls['accessCode'].setValue(this.configurationList.accessCode);
        this.settingForm.controls['mid'].setValue(this.configurationList.merchantId);
        this.settingForm.controls['Workingkey'].setValue(this.configurationList.workingKey);
        // this.settingForm.controls['Websitename'].setValue(this.configurationList.websiteName);
        this.settingForm.controls['baseurl'].setValue(this.configurationList.baseUrl);
        

        // this.settingForm.controls['logo'].setValue(this.configurationList.logo);
        // // this.settingForm.controls['logowithName'].setValue(this.configurationList.logoWithName);
        // // this.settingForm.controls['waterMark'].setValue(this.configurationList.waterMark);
        // this.settingForm.controls['copyright'].setValue(this.configurationList.copyrights);
        // this.settingForm.controls['poweredBy'].setValue(this.configurationList.poweredBy);
        // this.settingForm.controls['rights'].setValue(this.configurationList.rights);
        // this.settingForm.controls['configState'].setValue(this.configurationList.state.id);
        // this.settingForm.controls['configDistrict'].setValue(this.configurationList.district.id);
        // this.settingForm.controls['configCity'].setValue(this.configurationList.village.id);
        // this.settingForm.controls['configMunicipality'].setValue(this.configurationList.municipality.id);
        // this.settingForm.controls['longitude'].setValue(this.configurationList.location.coordinates[0]);
        // this.settingForm.controls['latitude'].setValue(this.configurationList.location.coordinates[1]);
        // this.settingForm.controls['doaStrtYear'].setValue(this.apiService.getDate1(this.configurationList.doaStartYear));
        // this.settingForm.controls['appVersion'].setValue(this.configurationList.appVersion);
        // this.settingForm.controls['configYoA'].setValue(this.configurationList.yoa.id);
        // this.settingForm.controls['captcha'].setValue(this.configurationList.isCaptcha);
        // this.settingForm.controls['configLegacy'].setValue(this.configurationList.isLegacy);
        // this.settingForm.controls['configMobileTower'].setValue(this.configurationList.isMobileTower);
        // this.settingForm.controls['configReports'].setValue(this.configurationList.reportMgmt);
        // this.settingForm.controls['configOperation'].setValue(this.configurationList.operationsMgmt);
        // this.settingForm.controls['configShopRent'].setValue(this.configurationList.shopRentMgmt);
        // this.settingForm.controls['configTradeLicense'].setValue(this.configurationList.tradeLisenceMgmt);
        // this.settingForm.controls['tlPart2'].setValue(this.configurationList.tlPart2);
        // this.settingForm.controls['configWaterBill'].setValue(this.configurationList.waterBillMgmt);        
    }
    

  )
}

  

}

<div class="app-container app-theme-white body-tabs-shadow fixed-header fixed-sidebar">
    <div class="app-main">
        <!-- User profile div start from here-->
        <div class="app-main__outer">
            <div class="app-main__inner">
                <div class="app-page-title">
                    <div class="page-title-wrapper">
                        <div class="page-title-heading">
                            <div class="page-title-icon">
                                <i class="pe-7s-users icon-gradient bg-tempting-azure"></i>
                            </div>
                            <div>{{'dataEntry' | translate}}</div>
                        </div>
                        <div class="page-title-actions" *ngIf="commonFun.getFeatureAccess(96)">
                            <a class="btn-wide mb-2 mr-2 btn-icon btn-icon-right btn-shadow btn-pill btn btn-outline-primary"
                                routerLink="/user_management/add-data-entry">{{'TCAdd' | translate}}<i
                                    class="pe-7s-plus btn-icon-wrapper">
                                </i></a>
                        </div>
                    </div>
                </div>
                <form [formGroup]="filterForm" (ngSubmit)="filterData()">
                    <div class="row">
                        <div class="col-lg-2">
                            <label>{{'TCFiltOrganization' | translate}}</label>
                            <select class="form-control" formControlName="organization"
                                [ngClass]="{'red-border-class':filterSubmitted && fl.organization.errors}"
                                (change)="getPM($event.target.value)">
                                <option value="" disabled>Select Organization</option>
                                <option value="{{data.uniqueId}}" *ngFor="let data of organiztionData">{{data?.name}}
                                </option>
                            </select>
                        </div>
                        <div class="col-lg-2">
                            <label>{{'TCProjectManager' | translate}}</label>
                            <select class="form-control" formControlName="pm"
                                [ngClass]="{'red-border-class':filterSubmitted && fl.pm.errors}">
                                <option value="" disabled>Select</option>
                                <option value="{{data.userName}}" *ngFor="let data of pmData">{{data?.name}}</option>
                            </select>
                        </div>
                        <div class="col-lg-2">
                            <label>{{'userFiltName' | translate}}</label>
                            <input type="text" formControlName="name" class="form-control" placeholder="Name">
                        </div>
                        <div class="col-lg-2">
                            <label>{{'userFiltContact' | translate}}</label>
                            <input type="text" formControlName="contact" class="form-control" placeholder="Contact">
                        </div>
                        <div class="col-lg-2">
                            <label>{{'userFiltUsername' | translate}}</label>
                            <input type="text" formControlName="userName" class="form-control" placeholder="Username">
                        </div>
                        <div class="col-lg-2">
                            <button style="margin-top: 35px;" type="submit"
                                class="btn btn-sm btn-primary">{{'TCBtnSearch' | translate}}</button> &nbsp; <button
                                style="margin-top: 35px;" (click)="reset()" type="button"
                                class="btn btn-sm btn-primary">{{'TCBtnReset' | translate}}</button>
                        </div>
                    </div>
                </form>
                <ul class="body-tabs body-tabs-layout tabs-animated body-tabs-animated nav">
                    <li class="nav-item" *ngIf="commonFun.getTabAccess(69)">
                        <a role="tab" class="nav-link active" id="tab-0" data-toggle="tab" href="#tab-content-0">
                            <span>{{'TCTabActive' | translate}}</span>
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="commonFun.getTabAccess(70)">
                        <a role="tab" class="nav-link" id="tab-1" data-toggle="tab" href="#tab-content-1">
                            <span>{{'TCTabDisabled' | translate}}</span>
                        </a>
                    </li>
                    <!-- <li class="nav-item" *ngIf="commonFun.getTabAccess(65)">
                        <a role="tab" class="nav-link" id="tab-1" data-toggle="tab" href="#tab-content-2">
                            <span>Track</span>
                        </a>
                    </li> -->
                </ul>
                <div class="tab-content">
                    <div *ngIf="commonFun.getTabAccess(69)" class="tab-pane tabs-animation fade show active"
                        id="tab-content-0" role="tabpanel">
                        <div class="main-card mb-3 card">
                            <div class="card-body" style="overflow-x: auto;">
                                <table  class="table table-hover table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Sr. No</th>
                                            <th>{{'TCTableName' | translate}}</th>
                                            <th>{{'TCcontact' | translate}}</th>
                                            <th>{{'TCOrganization' | translate}}</th>
                                            <th>{{'TCUserName' | translate}}</th>
                                            <th>{{'TCReportto' | translate}}</th>
                                            <th *ngIf="commonFun.getFeatureAccess(92)">{{'TCStatus' | translate}}</th>
                                            <th
                                                *ngIf="commonFun.getFeatureAccess(93) || commonFun.getFeatureAccess(94) || commonFun.getFeatureAccess(95)">
                                                {{'TCAction' | translate}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngIf="!employee||employee.length==0">
                                            <td colspan="8" style="text-align: center;">No Data Found</td>
                                        </tr>
                                        <tr *ngFor="let data of employee;index as i">
                                            <td>{{((pager.currentPage-1) *10)+(i+1)}}</td>
                                            <td>{{data?.name}}</td>
                                            <td>{{data?.mobile}}</td>
                                            <td>{{data?.ref?.organisation?.name}}</td>
                                            <td>{{data?.userName}}</td>
                                            <td>{{data?.ref?.manager?.name}}</td>
                                            <td *ngIf="commonFun.getFeatureAccess(92)">
                                                <label class="switch" (change)="status(data.userName,'disable')">
                                                    <input type="checkbox" checked>
                                                    <span class="slider round"></span>
                                                </label>
                                            </td>
                                            <td
                                                *ngIf="commonFun.getFeatureAccess(93) || commonFun.getFeatureAccess(94) || commonFun.getFeatureAccess(95)">
                                                <a *ngIf="commonFun.getFeatureAccess(84)" title="View"
                                                    (click)="userAction(data?.userName,'view')"><i
                                                        class="pe-7s-look btn-icon-wrapper"> </i> </a>&nbsp; 
                                                <a title="Edit" (click)="userAction(data?.userName,'edit')"
                                                    *ngIf="commonFun.getFeatureAccess(93)"><i
                                                        class="pe-7s-note btn-icon-wrapper"> </i> </a>&nbsp; <a
                                                    (click)="userAction(data?.userName,'delete')" title="Delete"
                                                    *ngIf="commonFun.getFeatureAccess(94)"> <i class="fa fa-trash"></i>
                                                </a> &nbsp; <a (click)="userAction(data?.userName,'reset')"
                                                    title="Reset Password" *ngIf="commonFun.getFeatureAccess(95)"><i
                                                        class="pe-7s-door-lock btn-icon-wrapper"> </i></a>&nbsp;
                                                        <a (click)="userAction(data?.userName,'logout')"
                                                        title="Force Logout" ><i
                                                            class="fa fa-sign-out aria-hidden='true'"> </i></a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <ul class="pagination mt-5" *ngIf="active_count > 10">
                                            <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
                                                <a class="page-link" (click)="setPage(1)">First</a>
                                            </li>
                                            <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
                                                <a class="page-link"
                                                    (click)="setPage(pager.currentPage - 1)">Previous</a>
                                            </li>
                                            <li class="page-item" *ngFor="let page of pager.pages"
                                                [ngClass]="{active:pager.currentPage === page}">
                                                <a class="page-link" (click)="setPage(page)">{{page}}</a>
                                            </li>
                                            <li class="page-item"
                                                [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                                                <a class="page-link" (click)="setPage(pager.currentPage + 1)">Next</a>
                                            </li>
                                            <li class="page-item"
                                                [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                                                <a class="page-link" (click)="setPage(pager.totalPages)">Last</a>
                                            </li>
                                        </ul>
                                        <label *ngIf="active_count">Total Record- {{active_count}}, Total Pages-
                                            {{pagen.totalPage}}</label>
                                    </div>
                                    <div class="col-lg-6">
                                        <ul class="pagination mt-5 pull-right" *ngIf="active_count >10">
                                            <li class="page-item" [ngClass]="{active:showItemActive == 10}">
                                                <a class="page-link" (click)="saveActiveItems(10)">10</a>
                                            </li>
                                            <li class="page-item" [ngClass]="{active:showItemActive == 50}">
                                                <a class="page-link" (click)="saveActiveItems(50)">50</a>
                                            </li>
                                            <li class="page-item" [ngClass]="{active:showItemActive == 100}">
                                                <a class="page-link" (click)="saveActiveItems(100)">100</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="commonFun.getTabAccess(70)" class="tab-pane tabs-animation fade" id="tab-content-1"
                        role="tabpanel">
                        <div class="main-card mb-3 card">
                            <div class="card-body" style="overflow-x: auto;">
                                <table class="table table-hover table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Sr. No</th>
                                            <th>{{'TCTableName' | translate}}</th>
                                            <th>{{'TCcontact' | translate}}</th>
                                            <th>{{'TCOrganization' | translate}}</th>
                                            <th>{{'TCUserName' | translate}}</th>
                                            <th>{{'TCReportto' | translate}}</th>
                                            <th *ngIf="commonFun.getFeatureAccess(92)">{{'TCStatus' | translate}}</th>
                                            <th
                                                *ngIf="commonFun.getFeatureAccess(93) || commonFun.getFeatureAccess(94) || commonFun.getFeatureAccess(95)">
                                                {{'TCAction' | translate}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngIf="!disEmployee||disEmployee.length==0">
                                            <td colspan="8" style="text-align: center;">No Data Found</td>
                                        </tr>
                                        <tr *ngFor="let data of disEmployee;index as i">
                                            <td>{{((pagenDisable.currentPage-1) *10)+(i+1)}}</td>
                                            <td>{{data?.name}}</td>
                                            <td>{{data?.mobile}}</td>
                                            <td>{{data?.ref?.organisation?.name}}</td>
                                            <td>{{data?.userName}}</td>
                                            <td>{{data?.ref?.manager?.name}}</td>
                                            <td *ngIf="commonFun.getFeatureAccess(92)"><label class="switch"
                                                    (change)="status(data.userName,'enable')">
                                                    <input type="checkbox">
                                                    <span class="slider round"></span>
                                                </label></td>
                                            <td
                                                *ngIf="commonFun.getFeatureAccess(93) || commonFun.getFeatureAccess(95) || commonFun.getFeatureAccess(94)">
                                                <a *ngIf="commonFun.getFeatureAccess(93)" title="View"
                                                    (click)="userAction(data?.userName,'view')"><i
                                                        class="pe-7s-look btn-icon-wrapper"> </i></a>&nbsp;
                                                <a title="Edit" *ngIf="commonFun.getFeatureAccess(93)"
                                                    (click)="userAction(data?.userName,'edit')"><i
                                                        class="pe-7s-note btn-icon-wrapper"> </i></a>&nbsp; <a
                                                    title="Delete" (click)="userAction(data?.userName,'delete')"
                                                    *ngIf="commonFun.getFeatureAccess(94)"> <i class="fa fa-trash"></i>
                                                </a> &nbsp; <a (click)="userAction(data?.userName,'reset')"
                                                    title="Reset Password" *ngIf="commonFun.getFeatureAccess(95)"><i
                                                        class="pe-7s-door-lock btn-icon-wrapper"> </i></a>&nbsp;
                                                        <a (click)="userAction(data?.userName,'logout')"
                                                        title="Force Logout" ><i
                                                            class="fa fa-sign-out aria-hidden='true'"> </i></a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <ul class="pagination mt-5" *ngIf="disable_count > 10">
                                            <li class="page-item" [ngClass]="{disabled:pagerDisable.currentPage === 1}">
                                                <a class="page-link" (click)="setPageDisble(1)">First</a>
                                            </li>
                                            <li class="page-item" [ngClass]="{disabled:pagerDisable.currentPage === 1}">
                                                <a class="page-link"
                                                    (click)="setPageDisble(pagerDisable.currentPage - 1)">Previous</a>
                                            </li>
                                            <li class="page-item" *ngFor="let page of pager.pages"
                                                [ngClass]="{active:pagerDisable.currentPage === page}">
                                                <a class="page-link" (click)="setPageDisble(page)">{{page}}</a>
                                            </li>
                                            <li class="page-item"
                                                [ngClass]="{disabled:pagerDisable.currentPage === pagerDisable.totalPages}">
                                                <a class="page-link"
                                                    (click)="setPageDisble(pagerDisable.currentPage + 1)">Next</a>
                                            </li>
                                            <li class="page-item"
                                                [ngClass]="{disabled:pagerDisable.currentPage === pagerDisable.totalPages}">
                                                <a class="page-link"
                                                    (click)="setPageDisble(pagerDisable.totalPages)">Last</a>
                                            </li>
                                        </ul>
                                        <label *ngIf="disable_count">Total Record- {{disable_count}}, Total Pages-
                                            {{pagenDisable.totalPage}}</label>
                                    </div>
                                    <div class="col-lg-6">
                                        <ul class="pagination mt-5 pull-right" *ngIf="disable_count>10">
                                            <li class="page-item" [ngClass]="{active:showItemDisable == 10}">
                                                <a class="page-link" (click)="saveDisableItems(10)">10</a>
                                            </li>
                                            <li class="page-item" [ngClass]="{active:showItemDisable == 50}">
                                                <a class="page-link" (click)="saveDisableItems(50)">50</a>
                                            </li>
                                            <li class="page-item" [ngClass]="{active:showItemDisable == 100}">
                                                <a class="page-link" (click)="saveDisableItems(100)">100</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <h2>Tooltip</h2>
                <p>Move the mouse over the text below:</p>
                <div class="tooltip">Hover over me <span class="tooltiptext">
                        <h1>Tooltip text</h1>
                    </span>
                </div> -->
            </div>
        </div>
        <!-- User profile div start from here-->
    </div>
</div>
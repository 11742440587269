import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as myGlobals from '../globals';
import { Observable } from 'rxjs';
import { ApiResponse } from "../services/api.response";
import alertifyjs from 'alertifyjs';
import { DomSanitizer} from "@angular/platform-browser";


@Injectable({
    providedIn: 'root'
})
export class ApiService {


    private LoggedInStatus: boolean = false;

    private APIURL = myGlobals.base_api_url;
    private AccessToken = sessionStorage.getItem('UserToken');

    constructor(private http: HttpClient,private sanitizer: DomSanitizer) { }

    postMethod(type, body): Observable<ApiResponse> {
        return this.http.post<ApiResponse>(this.APIURL + type, body)
    }
    postMethod1(type, body): Observable<ApiResponse> {
        return this.http.post<ApiResponse>(type, body)
    }
    postMethodWithOptions(type, body): Observable<ApiResponse> {
        let headers = new HttpHeaders({ 'Content-Type': "multipart/form-data; boundary=--------------------------766346391547012279608507" });

        // return this.http.post<ApiResponse>(this.APIURL + type, body, option)
        return this.http.post<ApiResponse>(this.APIURL + type, body, { "headers": headers })
    }

    getMethod(type, id): Observable<ApiResponse> {
        return this.http.get<ApiResponse>(this.APIURL + type + '?id=' + id)
    }

    getMethodres(type, id,notify): Observable<ApiResponse> {
        return this.http.get<ApiResponse>(this.APIURL + type + '?id=' + id +'&resType='+ notify)
    }


    getMethod1(type): Observable<ApiResponse> {
        return this.http.get<ApiResponse>(this.APIURL + type)
    }

    putMethod(type, id): Observable<ApiResponse> {
        return this.http.put<ApiResponse>(this.APIURL + type + '?id=' + id, {});
    }

    putMethod2(type, body): Observable<ApiResponse> {
        return this.http.put<ApiResponse>(this.APIURL + type, body)
    }

    putMethod3(type, id): Observable<ApiResponse> {
        return this.http.put<ApiResponse>(this.APIURL + type + '/' + id, {});
    }
    putMethod4(type, id,body): Observable<ApiResponse> {
        return this.http.put<ApiResponse>(this.APIURL + type + '?id=' + id, body);
    }
    delMethod(type, id): Observable<ApiResponse> {
        return this.http.delete<ApiResponse>(this.APIURL + type + '?id=' + id, {});
    }
    delMethodres(type, id,by, byType): Observable<ApiResponse> {
        return this.http.delete<ApiResponse>(this.APIURL + type + '?id=' + id + '&by='+by+'&byType='+ byType)
    }
    postFile(type, fileToUpload: File): Observable<ApiResponse> {
        const endpoint = this.APIURL + type;
        const formData: FormData = new FormData();
        formData.append('uploadfile', fileToUpload);
        return this.http.post<ApiResponse>(endpoint, formData);
    }
    postFiles(type, fileToUpload: Array<File>): Observable<ApiResponse> {
        const endpoint = this.APIURL + type;
        const formData: FormData = new FormData();
        for (let i = 0; i < fileToUpload.length; i++) {
            formData.append('uploadfiles', fileToUpload[i]);
        }
        return this.http.post<ApiResponse>(endpoint, formData);
    }
    postdocFiles(type, fileToUpload: Array<File>): Observable<ApiResponse> {
        const endpoint = this.APIURL + type;
        const formData: FormData = new FormData();
        for (let i = 0; i < fileToUpload.length; i++) {
            formData.append('docsupload', fileToUpload[i]);
        }
        return this.http.post<ApiResponse>(endpoint, formData);
    }
    public downloadReport(url, body): Observable<any> {
        return this.http.post(this.APIURL + url, body, {
            responseType: "blob",
            headers: new HttpHeaders().append("Content-Type", "application/json")
        });
    }
    public downloadReport1(url, body): Observable<any> {
        return this.http.get(this.APIURL + url+'?id=' + body, {
            responseType: "blob",
            headers: new HttpHeaders().append("Content-Type", "application/json")
        });
    }
    public downloadReport2(url, body): Observable<any> {
        return this.http.get(this.APIURL + url+'?id=' + body +'&resType=pdf', {
            responseType: "blob",
            headers: new HttpHeaders().append("Content-Type", "application/json")
        });
    }
    getFile(imageUrl: string): Observable<File> {
        return this.http.get<File>(this.APIURL + imageUrl);
    }
    getDate(date) {
        if (!date) {
            return "NA";
        }
        var d = new Date(date);
        let dateStr = (("0" + (d.getDate())).slice(-2) + "/" + ("0" + (d.getMonth() + 1)).slice(-2) + "/" + d.getFullYear());
        // console.log("Date str",dateStr);
        
        return dateStr
    }
    getDate1(date) {
        if (!date) {
            return "NA";
        }
        var d = new Date(date);
        return (d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + (d.getDate())).slice(-2));
    }
    downloadImage(url, name) {
        if (url == "") {
            alertifyjs.notify("No Url Available", "error", 2, function () { });
            return;
        }
        const imgUrl = this.APIURL + url;
        const imgName = name;
        this.http.get(imgUrl, { responseType: 'blob' as 'json' })
            .subscribe((res: any) => {
                const file = new Blob([res], { type: res.type });

                // IE
                // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                //     window.navigator.msSaveOrOpenBlob(file);
                //     return;
                // }

                const blob = window.URL.createObjectURL(file);
                const link = document.createElement('a');
                link.href = blob;
                link.download = imgName;

                // Version link.click() to work at firefox
                link.dispatchEvent(new MouseEvent('click', {
                    bubbles: true,
                    cancelable: true,
                    view: window
                }));

                setTimeout(() => { // firefox
                    window.URL.revokeObjectURL(blob);
                    link.remove();
                }, 100);
            });
    }
    sanitizePhotoURL(url) {

        if(url==''){
        return "../../../assets/images/th (1).jpg";
        }else{
            return this.sanitizer.bypassSecurityTrustResourceUrl(this.APIURL+ url);
        }
        // console.log("photo url - "+this.baseURL+ this.recordData.document)
        // return this.sanitizer.bypassSecurityTrustUrl(this.baseURL+ this.get_content.document);
      }
}


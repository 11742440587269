import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataSharingServiceService {
  public isUserLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public userDetails: BehaviorSubject<any> = new BehaviorSubject<any>('');
  public configuration: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public paymentFilters: BehaviorSubject<any> = new BehaviorSubject<any>({ state: null });



  public stateData: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  public districtData: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  public collector: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  public collectorPag: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  public financialYear: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  public paymentList: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public backToHomePage(router,config) {
    switch (config.name) {
      case "Kochas Nagar Panchayat":
        router.navigate(['/home-page'])
        break;
      case "Silao Nagar Panchayat":
        router.navigate(['/home-page'])
        break;
      case "Aurangabad Nagar Parishad":
        router.navigate(['/aurangabad-home-page']);
        break;
      case "Bakhtiyarpur Nagar Parishad":
        router.navigate(['/bakhtiyarpur-home-page']);
        break;
        case "Ramnagar Nagar Parishad":
          router.navigate(['/ramnagar-home-page']);
          break;
          case "Munger Nagar Nigam":
            router.navigate(['/munger-home-page']);
            break;
            case "Bhagalpur Nagar Nigam":
              router.navigate(['/bhagalpur-home-page']);
              break;
              case "Bhilai Nagar Nigam":
                router.navigate(['/home-page']);
                break;
      default:
        router.navigate(['/home-page'])
    }
  }

}

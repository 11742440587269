import { DataSharingServiceService } from './../../service/data-sharing-service.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import alertifyjs from 'alertifyjs';
import * as myGlobals from '../../globals';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  public baseUrl = myGlobals.base_api_url;
  loggedInObj: any;
  role: any;
  passwordForm: FormGroup;
  regisSubmitted: boolean = false;
  errValue: boolean = false;
  errText: string = '';
  public configuration: any;
  collectionconfig:boolean=false
  collectionconfig1:boolean=false
  constructor(private router: Router, private formBuilder: FormBuilder, private dataSharingService: DataSharingServiceService,
    private apiService: ApiService) { }

  ngOnInit(): void {
    this.loggedInObj = JSON.parse(sessionStorage.loggedInObj);
    this.getConfiguration()
    this.passwordForm = this.formBuilder.group({
      password: ["", Validators.required],
      cPassword: ["", Validators.required],
      oldpassword: ["", Validators.required],
    });
    this.dataSharingService.configuration.subscribe(
      data => {
        this.configuration = data.productConfiguration;
      }
    )
    // if (sessionStorage.script == "false")
    //   this.loadScript();
  }
  getConfiguration() {
  
    this.apiService.getMethod1("/product/configuration").subscribe(
      data => {
  
        this.configuration = data.response.data.productConfiguration;
        sessionStorage.setItem("config",JSON.stringify(this.configuration))
        if(this.configuration.tlPart2=='Yes')
        {
          this.collectionconfig=false
          this.collectionconfig1=true
        }else{
          this.collectionconfig=true
          this.collectionconfig1=false
        }
      })
    }
  logoutFun() {
    sessionStorage.setItem("isUserLoggedIn", "false");
    this.dataSharingService.isUserLoggedIn.next(false);
    this.dataSharingService.userDetails.next('');
    // alert("Do you want to Logout??")
    this.router.navigate(['/login']);
  }
  changePassword() {
    if (this.passwordForm.invalid) {
      this.regisSubmitted = true;
      alertifyjs.notify("Please Fill All Credentials", "error", 2, function () { });
      return;
    }
    this.regisSubmitted = false;
    if (this.passwordForm.value.password != this.passwordForm.value.cPassword) {
      this.errText = 'Password and Confirm Password should Same';
      this.errValue = true;
    } else {
      this.errValue = false;
    }
    // this.errValue = false;
    let body = {
      "userName": sessionStorage.userId,
      "oldPassword": this.passwordForm.value.oldpassword,
      "newPassword": this.passwordForm.value.password
    }
    this.apiService.postMethod("/user/changepassword", body).subscribe(
      data => {
        alertifyjs.notify("Successfully Changed", "success", 2, function () { });
        $("#exampleModal").modal("hide");
      }
    )
  }
  public loadScript() {
    let body = <HTMLDivElement>document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = '../../../assets/assets/scripts/main.d810cf0ae7f39f28f336.js';
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }
  getMenuAccess(key) {
    let role = sessionStorage.getItem('moduleAcl');
    this.role = JSON.parse(role);
    if (!this.role) {
      return;
    }
    for (let i = 0; i < this.role.MenuAccess.length; i++) {
      if (this.role.MenuAccess[i].uniqueId == key) {
        return this.role.MenuAccess[i].access.check == "Yes" ? true : false;
      }
    }
  }
  getModuleAccess(key) {
    let role = sessionStorage.getItem('moduleAcl');
    this.role = JSON.parse(role);
    if (!this.role) {
      return;
    }
    for (let i = 0; i < this.role.ModuleAccess.length; i++) {
      if (this.role.ModuleAccess[i].uniqueId == key) {
        return this.role.ModuleAccess[i].access.check == "Yes" ? true : false;
      }
    }
  }
}

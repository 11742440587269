import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ArvComponent } from './arv/arv.component';
import { ConnectiontypeComponent } from './connectiontype/connectiontype.component';
const routes: Routes =[
  {
  path:'arv',
    component:ArvComponent
  },
  {
  path:'Connectiontype',
  component:ConnectiontypeComponent
},
]


@NgModule({
  declarations: [ArvComponent, ConnectiontypeComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
  ]
})
export class WaterchargemgmtModule { }

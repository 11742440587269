<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-medal icon-gradient bg-tempting-azure"></i>
            </div>
            <div>Solid-Waste Category</div>
        </div>
        <div class="page-title-actions" >
            <a (click)="openAddModel()"
                class="btn-wide mb-2 mr-2 btn-icon btn-icon-right btn-shadow btn-pill btn btn-outline-success">Add<i class="pe-7s-plus btn-icon-wrapper">
                </i></a>
        </div>
    </div>
</div>
hhjkb
<div class="app-container app-theme-white body-tabs-shadow fixed-header fixed-sidebar">
    <div class="app-main">
        <!-- User profile div start from here-->
        <div class="app-main__outer">
            <div class="app-main__inner">
                <div class="app-page-title">
                    <div class="page-title-wrapper">
                        <div class="page-title-heading">
                            <div class="page-title-icon">
                                <i class="pe-7s-airplay icon-gradient bg-premium-dark"></i>
                            </div>
                            <div>View Data Entry</div>
                        </div>
                        <div class="page-title-actions">
                            <a class="btn-wide mb-2 mr-2 btn-icon btn-icon-right btn-shadow btn-pill btn btn-warning"
                                *ngIf="commonFun.getFeatureAccess(85)"
                                routerLink="/user_management/edit-data-entry">{{'TCViewEdit' | translate}}<i
                                    class="pe-7s-note btn-icon-wrapper">
                                </i></a>
                            <a class="btn-wide mb-2 mr-2 btn-icon btn-icon-right btn-shadow btn-pill btn btn-focus"
                            routerLink="/user_management/data-entry">{{'TCViewBack' | translate}}<i
                                    class="pe-7s-angle-left btn-icon-wrapper"> </i></a>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="main-card mb-3 card">
                            <div class="card-body">
                                <h5 class="card-title">{{'TCViewEmpMstr' | translate}}</h5><br>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label><strong>{{'TCViewEmpName' | translate}}<span
                                                        class='red'>*</span></strong></label>
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span class="btn btn-secondary">
                                                        <i class="fa fa-user"></i>
                                                    </span>
                                                </div>
                                                <input type="text" value="{{userData?.name}}" class="form-control"
                                                    disabled>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label><strong>{{'TCViewContact' | translate}}<span
                                                        class='red'>*</span></strong></label>
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span class="btn btn-secondary">
                                                        <i class="fa fa-phone"></i>
                                                    </span>
                                                </div>
                                                <input type="text" class="form-control" value="{{userData?.mobile}}"
                                                    disabled>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label><strong>{{'TCViewEmail' | translate}}<span
                                                        class='red'>*</span></strong></label>
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span class="btn btn-secondary">
                                                        <i class="fa fa-envelope"></i>
                                                    </span>
                                                </div>
                                                <input type="text" class="form-control" value="{{userData?.email}}"
                                                    disabled>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-6">
                                        <div class="form-group">
                                            <label><strong>{{'TCViewStatus' | translate}}<span
                                                        class='red'>*</span></strong></label>
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span class="btn btn-secondary">
                                                        <i class="fa fa-user-secret"></i>
                                                    </span>
                                                </div>
                                                <select class="form-control" disabled id="status">
                                                    <option value="Active">Active</option>
                                                    <option value="Disabled">Deactive</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label><strong>{{'TCViewEmpOf' | translate}}<span
                                                        class='red'>*</span></strong></label>
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span class="btn btn-secondary">
                                                        <i class="fa fa-building"></i>
                                                    </span>
                                                </div>
                                                <select class="form-control" id="companyDd" disabled>
                                                    <option value="" disabled>Select</option>
                                                    <option value="{{data?.uniqueId}}" *ngFor="let data of companyData">
                                                        {{data?.name}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label><strong>{{'TCViewReportTo' | translate}}<span
                                                        class='red'>*</span></strong></label>
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span class="btn btn-secondary">
                                                        <i class="fa fa-id-badge"></i>
                                                    </span>
                                                </div>
                                                <input type="text" class="form-control" value="{{userData?.ref?.manager?.name}}"
                                                disabled>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label><strong>Select Designation<span class='red'>*</span></strong></label>
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span class="btn btn-secondary">
                                                        <i class="fa fa-id-badge"></i>
                                                    </span>
                                                </div>
                                                <select class="form-control" disabled id="designation">
                                                    <option value="" disabled>--Select Designation---</option>
                                                    <option value="1">Circle Officer</option>
                                                    <option value="2">Back Office</option>
                                                    <option value="3">Accounts</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="main-card mb-3 card">
                            <div class="card-body">
                                <h5 class="card-title">{{'TCViewEmpPicture' | translate}}</h5><br>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="input-group">
                                            <img src="{{imageSrc}}" style="margin-top: -14px;
                                    border: 2px solid gray;
                                    border-radius: 6px;height: 100px;width: 100px;">
                                        </div>
                                    </div>
                                </div>
                                <br>
                                <hr>
                                <div class="row">
                                    <div class="col-md-6">
                                        <!-- <button class="mb-2 mr-2 btn btn-gradient-success">View</button> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- User profile div start from here-->
    </div>
</div>
<div class="app-drawer-overlay d-none animated fadeIn"></div>
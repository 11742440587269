import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonfunService {
  role: any;
  constructor() { }
  getTabAccess(key) {
    let role = sessionStorage.getItem('moduleAcl');
    this.role = JSON.parse(role);
    if (!this.role) {
      return;
    }
    for (let i = 0; i < this.role.TabAccess.length; i++) {
      if (this.role.TabAccess[i].uniqueId == key) {
        return this.role.TabAccess[i].access.check == "Yes" ? true : false;
      }
    }
  }
  getFeatureAccess(key) {
    let role = sessionStorage.getItem('moduleAcl');
    this.role = JSON.parse(role);
    if (!this.role) {
      return;
    }
    for (let i = 0; i < this.role.FeatureAccess.length; i++) {
      if (this.role.FeatureAccess[i].uniqueId == key) {
        return this.role.FeatureAccess[i].access.check == "Yes" ? true : false;
      }
    }
  }
  getMenuAccess(key) {
    let role = sessionStorage.getItem('moduleAcl');
    this.role = JSON.parse(role);
    if (!this.role) {
      return;
    }
    for (let i = 0; i < this.role.MenuAccess.length; i++) {
      if (this.role.MenuAccess[i].uniqueId == key) {
        return this.role.MenuAccess[i].access.check == "Yes" ? true : false;
      }
    }
  }
}

import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { map, tap } from 'rxjs/operators';
import {  HttpHeaders } from '@angular/common/http';
import {  ApiService } from './services/api.service';

import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { DataSharingServiceService } from './service/data-sharing-service.service';
import { Router } from '@angular/router';

import { Observable ,throwError} from 'rxjs';

@Injectable()
export class ExampleInterceptorInterceptor implements HttpInterceptor {
  loggedInUser: any=null;

  constructor(private router: Router,private dataSharingService: DataSharingServiceService,private apiService:ApiService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let isUserLoggedIn = "No"
    let loggedInUserName = ""
    let userName = ""
    let token = ""

    if (sessionStorage.loggedInObj){
      this.loggedInUser = JSON.parse(sessionStorage.loggedInObj);
      loggedInUserName = this.loggedInUser.userName
    }
    if(sessionStorage.isUserLoggedIn){
      let isUserLoggedInSS = JSON.parse(sessionStorage.isUserLoggedIn);
      if (isUserLoggedInSS == true){
            isUserLoggedIn = "Yes"
          }
    }
    if(sessionStorage.token){
      let tokenvalue = sessionStorage.token;
      if (tokenvalue){
          token=tokenvalue
          }
    }
    // let isUserLoggedInSS = JSON.parse(sessionStorage.isUserLoggedIn);
  //  let isUserLoggedIn = "No"
  //   if (isUserLoggedInSS == "true"){
  //     isUserLoggedIn = "Yes"
  //   }
 
//  console.log("headers",request);
 
    const authReq = request.clone({
      headers:    request.headers.set('requestAgentType', `web`)
      .set('isLoggedIn', isUserLoggedIn)
      .set('userName', loggedInUserName)
      .set('Authorization', token)

  });
  // console.log(authReq);
  
    return next.handle(authReq)
     .pipe(
      catchError(
      
      
      err => {
      console.log(err.status);
      
      if ([401, 403].includes(err.status) ) {
          // auto logout if 401 or 403 response returned from api
          this.logoutFun();
      }

      const error = err.error?.message || err.statusText;
      console.error(err);
      return throwError(() => err.error?.message);
      
  }
  )
  // tap({
    
  //   error: (error) => {
  //     if(error.status == 401) {
  //       alert('Unauthorize access!!!')
  //     }
  //     if(error.status == 404) {
  //       alert('Page Not Found!!!')
  //     }
  //   }

  // }
  // )
  );
  }
  logoutFun() {
    sessionStorage.setItem("isUserLoggedIn", "false");
    this.dataSharingService.isUserLoggedIn.next(false);
    this.dataSharingService.userDetails.next('');
    this.router.navigate(['/login']);
  }
  private addExtraHeaders(headers: HttpHeaders,isLoggedIn): HttpHeaders {
    headers = headers.append('requestAgentType', 'web').append('isLoggedIn', 'isLoggedIn');
 
    return headers;
  } 
}

import { Component, OnInit } from '@angular/core';
import { ApiService } from "../../services/api.service";
import { Router } from "@angular/router";
import { PagerService } from "../../services/pager.service";
import { CommonfunService } from "src/app/services/commonfun.service";
import { NgxSpinnerService } from "ngx-spinner";
import alertifyjs from 'alertifyjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-data-entry',
  templateUrl: './data-entry.component.html',
  styleUrls: ['./data-entry.component.css']
})
export class DataEntryComponent implements OnInit {
  employee: any = [];
  userType: any = [{
    key: '1',
    value: 'Circle Officer'
  }, {
    key: '2',
    value: 'Back Office'
  }, {
    key: '3',
    value: 'Accounts'
  },];
  active_count: 0;
  pager: any = {};
  pagen: any = {};
  disable_count: 0;
  pagerDisable: any = {};
  pagenDisable: any = {};
  showItemActive: number = 10;
  showItemDisable: number = 10;
  disEmployee: any = [];
  filterForm: FormGroup;
  activeBody: any = {};
  inactiveBody: any = {};
  organiztionData: any = [];
  filterSubmitted: boolean;
  pmData: any = [];
  lat = 25.231497;
  long = 83.920297;
  zoom = 15;
  markers = [
    // {
    //   lat: 21.1594627,
    //   lng: 72.6822083,
    //   label: 'Surat'
    // },
    // {
    //   lat: 23.0204978,
    //   lng: 72.4396548,
    //   label: 'Ahmedabad'
    // },
    // {
    //   lat: 22.2736308,
    //   lng: 70.7512555,
    //   label: 'Rajkot'
    // },
    // {
    //   lat: 21.1702,
    //   lng: 72.8311,
    //   label: 'Surat'
    // },
    // {
    //   lat: 22.3072,
    //   lng: 73.1812,
    //   label: 'vadodara'
    // }
  ];
  mapData: any = [];
  constructor(public translate: TranslateService, private apiService: ApiService, private router: Router, private spinner: NgxSpinnerService,
    private pagerService: PagerService, public commonFun: CommonfunService, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.filterForm = this.formBuilder.group(
      {
        organization: ['', Validators.required],
        pm: ['', Validators.required],
        name: ['', Validators.required],
        contact: ['', Validators.required],
        userName: ['', Validators.required]
      });
    this.activeBody = {
      status: ["Active"],
      type: ["DEO"]
    }
    this.inactiveBody = {
      status: ["Disabled"],
      type: ["DEO"]
    }
    this.getOrganization();
    this.getAllEmployee(1);
    this.getAllDisalbeEmployee(1);
    // this.getMapVal();

  }
  onMouseOver(infoWindow, $event: MouseEvent) {
    infoWindow.open();
  }
  onMouseOut(infoWindow, $event: MouseEvent) {
    infoWindow.close();
  }
  saveActiveItems(number) {
    this.showItemActive = number;
    this.getAllEmployee(this.pager.currentPage);
  }
  saveDisableItems(number) {
    this.showItemDisable = number;
    this.getAllDisalbeEmployee(this.pagerDisable.currentPage);
  }
  userAction(id, type) {
    sessionStorage.setItem("dataId", id);
    if (type == 'view') {
      this.router.navigate(['/user_management/view-data-entry']);
    } else if (type == 'delete') {
      if (confirm(this.translate.instant('alrtDelete'))) {
        this.spinner.show();
        this.apiService.delMethod("/user/status/delete", id).subscribe(
          data => {
            this.spinner.hide();
            alertifyjs.notify(this.translate.instant('alrtSuccessfullyDeleted'), "success", 2, function () { });
            this.getAllEmployee(this.pager.currentPage);
            this.getAllDisalbeEmployee(this.pagerDisable.currentPage);
          },
          err => {
            this.spinner.hide();
          }
        )
      }
    } else if (type == 'reset') {
      if (confirm(this.translate.instant('alrtRstPswdMsg'))) {
        this.spinner.show();
        this.apiService.putMethod("/user/resetpassword", id).subscribe(
          data => {
            this.spinner.hide();
            alertifyjs.notify(this.translate.instant('alrtSuccessfullyPswdRst'), "success", 2, function () { });
          },
          err => {
            this.spinner.hide();
          }
        )
      }
    }
    else if(type=="logout")
    {
      if (confirm(this.translate.instant('alrtforcelogout'))) {
     let usernamedata=id
  
      this.apiService.putMethod("/user/removedtoken",usernamedata).subscribe(
        data => {
        },
        err => {
          this.spinner.hide();
        }
      )
      }
     
    }
    else {
      this.router.navigate(['/user_management/edit-data-entry']);
    }
  }
  status(id, type) {
    if (type == 'enable' || type == 'disable') {
      this.spinner.show();
      this.apiService.putMethod('/user/status/' + type, id).subscribe((data: any) => {
        this.spinner.hide();
        this.getAllEmployee(this.pager.currentPage);
        this.getAllDisalbeEmployee(this.pagerDisable.currentPage);
      },
        err => {
          this.spinner.hide();
          console.log(err);
        });
    }
    else if (type == 'delete') {
      if (confirm(this.translate.instant('alrtDelete'))) {
        this.spinner.show();
        this.apiService.putMethod('/user/status/' + type, id).subscribe((data: any) => {
          this.spinner.hide();
        },
          err => {
            this.spinner.hide();
            console.log(err);
          });
      }
    }
  }
  setPage(page: number) {
    if (page < 1 || page > this.pagen.totalPage) {
      return;
    }
    this.getAllEmployee(page);
  }
  getAllEmployee(pageno) {
    this.spinner.show();
    this.apiService.postMethod("/user/filter?pageno=" + pageno + "&limit=10", this.activeBody).subscribe(
      data => {
        this.spinner.hide();
        this.employee = data.response.data.user;
        this.active_count = data.response.data.pagination.count;
        this.pagen.totalUsers = data.response.data.pagination.count;
        this.pagen.totalPage = data.response.data.pagination.totalPage;
        this.pagen.currentPage = data.response.data.pagination.pageNum;
        this.pager = this.pagerService.getPager(this.pagen.totalUsers, pageno, 10);
        if ((pageno > 1) && (!this.employee.length)) {
          this.getAllEmployee(pageno - 1);
        }
      }, err => {
        this.spinner.hide();
      }
    )
  }
  setPageDisble(page: number) {
    if (page < 1 || page > this.pagenDisable.totalPage) {
      return;
    }
    this.getAllDisalbeEmployee(page);
  }
  getAllDisalbeEmployee(pageno) {
    this.spinner.show();
    this.apiService.postMethod("/user/filter?pageno=" + pageno + "&limit=10", this.inactiveBody).subscribe(
      data => {
        this.spinner.hide();
        this.disEmployee = data.response.data.user;
        this.disable_count = data.response.data.pagination.count;
        this.pagenDisable.totalUsers = data.response.data.pagination.count;
        this.pagenDisable.totalPage = data.response.data.pagination.totalPage;
        this.pagenDisable.currentPage = data.response.data.pagination.pageNum;
        this.pagerDisable = this.pagerService.getPager(this.pagenDisable.totalUsers, pageno, 10);
        if ((pageno > 1) && (!this.disEmployee.length)) {
          this.getAllDisalbeEmployee(pageno - 1);
        }
      }, err => {
        this.spinner.hide();
      }
    )
  }
  get fl() {
    return this.filterForm.controls;
  }
  getOrganization() {
    let body = {
      status: ["Active"]
    }
    this.spinner.show();
    this.apiService.postMethod("/organisation/filter?pageno=no", body).subscribe(
      data => {
        this.spinner.hide();
        this.organiztionData = data.response.data.organisation;
      },
      err => {
        this.spinner.hide();
      }
    )
  }
  getPM(id) {
    let body = {
      "status": ["Active"],
      "organisationId": [id],
      "type": ["PM"],
    }
    this.spinner.show();
    this.apiService.postMethod("/user/filter?pageno=no", body).subscribe(
      data => {
        this.spinner.hide();
        this.pmData = data.response.data.user;
      },
      err => {
        this.spinner.hide();
      }
    )
  }
  getMapVal() {
    let body = {
      "status": ["Active"],
      "type": ["DEO"],
      "getRecentLocation": true
    }
    this.spinner.show();
    this.apiService.postMethod("/user/filter?pageno=no", body).subscribe(
      data => {
        this.spinner.hide();
        this.mapData = data.response.data.user;
        // for (let i = 0; i < this.mapData.length; i++) {
        //   if (this.mapData[i].ref.lastLocation) {
        //     this.markers[i] = {
        //       lat: this.mapData[i].ref.lastLocation.location.coordinates[1],
        //       lng: this.mapData[i].ref.lastLocation.location.coordinates[0],
        //       label: 'Surat'
        //     }
        //   }
        // }
        console.log(this.markers);
      },
      err => {
        this.spinner.hide();
      }
    )
  }
  filterData() {
    this.filterSubmitted = false;
    this.activeBody = {
      "status": ["Active"],
      "organisationId": this.filterForm.value.organization ? [this.filterForm.value.organization] : [],
      "type": ["DEO"],
      "manager": this.filterForm.value.pm ? [this.filterForm.value.pm] : [],
      regex: {
        "name": this.filterForm.value.name,
        "contact": this.filterForm.value.contact,
        "userName": this.filterForm.value.userName
      }
    }
    this.inactiveBody = {
      status: ["Disabled"],
      "organisationId": this.filterForm.value.organization ? [this.filterForm.value.organization] : [],
      "type": ["DEO"],
      "manager": this.filterForm.value.pm ? [this.filterForm.value.pm] : [],
      regex: {
        "name": this.filterForm.value.name,
        "contact": this.filterForm.value.contact,
        "userName": this.filterForm.value.userName
      }
    }
    this.getAllEmployee(1);
    this.getAllDisalbeEmployee(1);
  }
  reset() {
    this.activeBody = {
      status: ["Active"],
      type: ["DEO"]
    }
    this.inactiveBody = {
      status: ["Disabled"],
      type: ["DEO"]
    }
    this.filterForm.controls['organization'].setValue('');
    this.filterForm.controls['pm'].setValue('');
    this.filterForm.controls['name'].setValue('');
    this.filterForm.controls['contact'].setValue('');
    this.filterForm.controls['userName'].setValue('');
    this.getAllEmployee(1);
    this.getAllDisalbeEmployee(1);
  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from "@angular/router";
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { PagerService } from 'src/app/services/pager.service';
declare var $: any;
import alertifyjs from 'alertifyjs';
import { CommonfunService } from 'src/app/services/commonfun.service';

@Component({
  selector: 'app-user-type',
  templateUrl: './user-type.component.html',
  styleUrls: ['./user-type.component.css']
})
export class UserTypeComponent implements OnInit {
  userData: any = [];
  userDataDisable: any = [];
  active_count: 0;
  pager: any = {};
  pagen: any = {};
  disable_count: 0;
  pagerDisable: any = {};
  pagenDisable: any = {};
  addState: FormGroup;
  viewState: FormGroup;
  editState: FormGroup;
  regisSubimitted: boolean = false;

  showItemActive: number = 10;
  showItemDisable: number = 10;
  constructionTypeid: string;

  constructor(private formBuilder: FormBuilder, private apiservice: ApiService, private router: Router,
    private spinner: NgxSpinnerService, private pagerService: PagerService, public commonFun: CommonfunService) { }

  ngOnInit(): void {
    this.editState = this.formBuilder.group(
      {
        name: ['', Validators.required],
        description: ['']
      })
    this.viewState = this.formBuilder.group(
      {
        name: ['', Validators.required],
        description: ['']
      })

    this.addState = this.formBuilder.group(
      {
        name: ['', Validators.required],
        description: ['']
      })

    this.getAllList(1);
    this.getAllDisableList(1);
  }
  saveActiveItems(number) {
    this.showItemActive = number;
    this.getAllList(this.pager.currentPage);
  }
  saveDisableItems(number) {
    this.showItemDisable = number;
    this.getAllDisableList(this.pagerDisable.currentPage);
  }
  status(id, type) {
    if (type == 'enable' || type == 'disable') {
      this.spinner.show();
      this.apiservice.putMethod('/usertype/status/' + type, id).subscribe((data: any) => {
        this.spinner.hide();
        this.getAllList(this.pager.currentPage);
        this.getAllDisableList(this.pagerDisable.currentPage);
      },
        err => {
          this.spinner.hide();
          console.log(err);
        });
    }
    else if (type == 'delete') {
      if (confirm("Are you sure to Delete")) {
        this.spinner.show();
        this.apiservice.delMethod('/usertype/status/' + type, id).subscribe((data: any) => {
          this.spinner.hide();
          this.getAllList(this.pager.currentPage);
          this.getAllDisableList(this.pagerDisable.currentPage);
        },
          err => {
            this.spinner.hide();
            console.log(err);
          });
      }
    }
  }
  // get fr(){
  //   return this.editState.controls;
  // }
  get construction() {
    return this.addState.controls;
  }
  save() {
    if (this.addState.invalid) {
      this.regisSubimitted = true
      return
    }
    this.regisSubimitted = false;
    let body = {
      "name": this.addState.value.name,
      "desc": this.addState.value.description
    }
    this.apiservice.postMethod("/usertype", body).subscribe(
      data => {
        alertifyjs.notify("Successfully Created", "success", 2, function () { });
        $("#addRecord").modal("hide");
        this.addState.reset();
        this.getAllList(this.pager.currentPage);
      }
    )
  }

  getSingleconstructionType(id) {
    this.spinner.show();
    this.apiservice.getMethod("/usertype", id).subscribe(
      data => {
        this.spinner.hide();

        let userData = data.response.data.userType;
        this.editState.controls['name'].setValue(userData.name);
        this.editState.controls['description'].setValue(userData.desc);
      },
      err => {
        this.spinner.hide();
      }
    )

  }

  userAction(type, id) {
    this.constructionTypeid = id;
    if (type == "edit") {
      $("#editRecord").modal("show");
      this.getSingleconstructionType(id);
    }
    else if (type == "view") {
      $("#viewRecord").modal("show");
      this.getSingleconstructionViewType(id);
    }
  }

  get for() {
    return this.viewState.controls;
  }
  getSingleconstructionViewType(id) {
    this.spinner.show();
    this.apiservice.getMethod("/usertype", id).subscribe(
      data => {
        this.spinner.hide();
        console.log(data);
        let userData = data.response.data.userType;
        this.viewState.controls['name'].setValue(userData.name);
        this.viewState.controls['description'].setValue(userData.desc);
      },
      err => {
        this.spinner.hide();
      }
    )

  }

  get fr() {
    return this.editState.controls;
  }
  updateStateType() {
    if (this.editState.invalid) {
      this.regisSubimitted = true
      return;
    }
    this.regisSubimitted = false;
    let body = {
      "uniqueId": this.constructionTypeid,
      "name": this.editState.value.name,
      "desc": this.editState.value.description
    }
    this.spinner.show();
    this.apiservice.putMethod2("/usertype", body).subscribe(
      data => {
        this.spinner.hide();
        alertifyjs.notify("Successfully Updated", "success", 2, function () { });
        $("#editRecord").modal("hide");
        this.getAllList(this.pager.currentPage);
      },
      err => {
        this.spinner.hide();
      })
  }
  setPage(page: number) {
    if (page < 1 || page > this.pagen.totalPage) {
      return;
    }
    this.getAllList(page);
  }
  openModal() {
    $("#addRecord").modal("show");
  }
  // closeModal() {
  //   $("#addRecord").modal("hide");

  // }
  getAllList(pageno) {
    let body = {
      status: ["Active"]
    }
    this.spinner.show();
    this.apiservice.postMethod("/usertype/filter?pageno=" + pageno + "&limit=" + this.showItemActive, body).subscribe(
      data => {
        this.spinner.hide();
        this.userData = data.response.data.userType;
        this.active_count = data.response.data.pagination.count;
        this.pagen.totalUsers = data.response.data.pagination.count;
        this.pagen.totalPage = data.response.data.pagination.totalPage;
        this.pagen.currentPage = data.response.data.pagination.pageNum;
        this.pager = this.pagerService.getPager(this.pagen.totalUsers, pageno, this.showItemActive);
        if ((pageno > 1) && (!this.userData.length)) {
          this.getAllDisableList(pageno - 1);
        }
      },
      err => {
        this.spinner.hide();
      })
  }
  setPageDisble(page: number) {
    if (page < 1 || page > this.pagenDisable.totalPage) {
      return;
    }
    this.getAllDisableList(page);
  }
  getAllDisableList(pageno) {
    let body = {
      status: ["Disabled"]
    }
    this.spinner.show();
    this.apiservice.postMethod("/usertype/filter?pageno=" + pageno + "&limit=" + this.showItemDisable, body).subscribe(
      data => {
        this.spinner.hide();
        this.userDataDisable = data.response.data.userType;
        this.disable_count = data.response.data.pagination.count;
        this.pagenDisable.totalUsers = data.response.data.pagination.count;
        this.pagenDisable.totalPage = data.response.data.pagination.totalPage;
        this.pagenDisable.currentPage = data.response.data.pagination.pageNum;
        this.pagerDisable = this.pagerService.getPager(this.pagenDisable.totalUsers, pageno, this.showItemDisable);
        if ((pageno > 1) && (!this.userDataDisable.length)) {
          this.getAllDisableList(pageno - 1);
        }
      },
      err => {
        this.spinner.hide();
      })
  }
}

<div class="app-container app-theme-white body-tabs-shadow fixed-header fixed-sidebar">
    <div class="app-main">
        <!-- User profile div start from here-->
        <div class="app-main__outer">
            <div class="app-main__inner">
                <div class="app-page-title">
                    <div class="page-title-wrapper">
                        <div class="page-title-heading">
                            <div class="page-title-icon">
                                <i class="pe-7s-medal icon-gradient bg-tempting-azure"></i>
                            </div>
                            <div>HDFC Payment-Gateway</div>
                        </div>
                        <div class="page-title-actions">
                            <a (click)="editbtn()"
                                class="btn-wide mb-2 mr-2 btn-icon btn-icon-right btn-shadow btn-pill btn btn-outline-success" 
                                >Edit<i class="pe-7s-plus btn-icon-wrapper">
                                </i></a>
                        </div>
                    </div>
                </div>
                <form [formGroup]="settingForm">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="main-card mb-3 card">
                                <div class="card-body">
                                    <!--<h5 class="card-title">Property Details</h5>-->
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label><strong>AccessCode<span
                                                            class='red'>*</span></strong></label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="btn btn-secondary">
                                                            <i class="fa fa-building"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" formControlName="accessCode" class="form-control">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label><strong>MID<span
                                                            class='red'>*</span></strong></label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="btn btn-secondary">
                                                            <i class="fa fa-building"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" formControlName="mid" class="form-control">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label><strong>Working Key<span
                                                            class='red'>*</span></strong></label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="btn btn-secondary">
                                                            <i class="fa fa-id-badge"></i>
                                                        </span>
                                                    </div>
                                                    <input formControlName="Workingkey" class="form-control" type="text">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label><strong>Base URL<span
                                                            class='red'>*</span></strong></label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="btn btn-secondary">
                                                            <i class="fa fa-id-badge"></i>
                                                        </span>
                                                    </div>
                                                    <input formControlName="baseurl" class="form-control" type="text">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                      
                                <div class="row">
                                        <div class="col-lg-4">
                                            <button type="submit" class="btn btn-secondary" (click)="ProductConfig()">Submit</button>
                                        </div>
                                    </div>
                </form>
            </div>
        </div>
        <!-- User profile div start from here-->
    </div>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { SettingsComponent } from './settings/settings.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { SelectLngModule } from '../select-lng/select-lng.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HdfcComponent } from './hdfc/hdfc.component';


const routes: Routes = [
  {
    path: 'settings',
    component: SettingsComponent
  },
    {
      path: 'hdfc',
      component: HdfcComponent
    },
];


@NgModule({
  declarations: [SettingsComponent, HdfcComponent ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgxSpinnerModule,
    RouterModule.forChild(routes),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SelectLngModule
  ]
})
export class AdminModule { 
  constructor(translate: TranslateService) {
    translate.addLangs(['English', 'Hindi']);
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/English|Hindi/) ? browserLang : (sessionStorage.language || 'English'));
  }
}

export function translateLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

<div class="app-container app-theme-white body-tabs-shadow fixed-header fixed-sidebar">
    <div class="app-main">
        <!-- User profile div start from here-->
        <div class="app-main__outer">
            <div class="app-main__inner">
                <div class="app-page-title">
                    <div class="page-title-wrapper">
                        <div class="page-title-heading">
                            <div class="page-title-icon">
                                <i class="pe-7s-medal icon-gradient bg-tempting-azure"></i>
                            </div>
                            <div>{{'sbUserType' | translate}}</div>
                        </div>
                        <div class="page-title-actions" *ngIf="commonFun.getFeatureAccess(100)">
                            <a (click)="openModal()"
                                class="btn-wide mb-2 mr-2 btn-icon btn-icon-right btn-shadow btn-pill btn btn-outline-success">{{'stateBtnAdd'
                                | translate}}<i class="pe-7s-plus btn-icon-wrapper">
                                </i></a>
                        </div>
                    </div>
                </div>
                <ul class="body-tabs body-tabs-layout tabs-animated body-tabs-animated nav">
                    <li class="nav-item" *ngIf="commonFun.getTabAccess(67)">
                        <a role="tab" class="nav-link active" id="tab-0" data-toggle="tab" href="#tab-content-0">
                            <span>{{'stateTabActive' | translate}}</span>
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="commonFun.getTabAccess(68)">
                        <a role="tab" class="nav-link" id="tab-1" data-toggle="tab" href="#tab-content-1">
                            <span>{{'stateTabDisabled' | translate}}</span>
                        </a>
                    </li>
                </ul>
                <div class="tab-content">
                    <div *ngIf="commonFun.getTabAccess(67)" class="tab-pane tabs-animation fade show active"
                        id="tab-content-0" role="tabpanel">
                        <div class="main-card mb-3 card">
                            <div class="card-body" style="overflow-x: auto;">
                                <table class="table table-hover table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Sr. No</th>
                                            <th>{{'stateTCName' | translate}}</th>
                                            <th>{{'stateTCDesc' | translate}}</th>
                                            <th *ngIf="commonFun.getFeatureAccess(97)">{{'stateTCStatus' | translate}}
                                            </th>
                                            <th
                                                *ngIf="commonFun.getFeatureAccess(98) || commonFun.getFeatureAccess(99) || commonFun.getFeatureAccess(101)">
                                                {{'stateTCAction' | translate}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngIf="!userData||userData.length==0">
                                            <td colspan="5" style="text-align: center;">No Data Found</td>
                                        </tr>
                                        <tr *ngFor="let data of userData;index as i">
                                            <td>{{((pager.currentPage-1) * showItemActive)+(i+1)}}</td>
                                            <td>{{data?.name}}</td>
                                            <td>{{data?.desc}}</td>
                                            <td *ngIf="commonFun.getFeatureAccess(97)"><label class="switch"
                                                    (change)="status(data.uniqueId,'disable')">
                                                    <input type="checkbox" checked>
                                                    <span class="slider round"></span>
                                                </label></td>
                                            <td
                                                *ngIf="commonFun.getFeatureAccess(98) || commonFun.getFeatureAccess(99) || commonFun.getFeatureAccess(101)">
                                                <a *ngIf="commonFun.getFeatureAccess(98)"
                                                    (click)="userAction('view',data?.uniqueId)"><i
                                                        class="pe-7s-look btn-icon-wrapper"> </i></a>&nbsp; <a
                                                    *ngIf="commonFun.getFeatureAccess(99)"
                                                    (click)="userAction('edit',data?.uniqueId)"><i
                                                        class="pe-7s-note btn-icon-wrapper"> </i></a>&nbsp; <a
                                                    *ngIf="commonFun.getFeatureAccess(101)" class="danger p-0"
                                                    (click)="status(data?.uniqueId,'delete')" title="Delete">
                                                    <i class="fa fa-trash font-medium-3 mr-2"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <ul class="pagination mt-5" *ngIf="active_count > 10">
                                            <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
                                                <a class="page-link" (click)="setPage(1)">First</a>
                                            </li>
                                            <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
                                                <a class="page-link"
                                                    (click)="setPage(pager.currentPage - 1)">Previous</a>
                                            </li>
                                            <li class="page-item" *ngFor="let page of pager.pages"
                                                [ngClass]="{active:pager.currentPage === page}">
                                                <a class="page-link" (click)="setPage(page)">{{page}}</a>
                                            </li>
                                            <li class="page-item"
                                                [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                                                <a class="page-link" (click)="setPage(pager.currentPage + 1)">Next</a>
                                            </li>
                                            <li class="page-item"
                                                [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                                                <a class="page-link" (click)="setPage(pager.totalPages)">Last</a>
                                            </li>
                                        </ul>
                                        <label *ngIf="active_count">Total Record- {{active_count}}, Total Pages-
                                            {{pagen.totalPage}}</label>
                                    </div>
                                    <div class="col-lg-6">
                                        <ul class="pagination mt-5 pull-right" *ngIf="active_count >10">
                                            <li class="page-item" [ngClass]="{active:showItemActive == 10}">
                                                <a class="page-link" (click)="saveActiveItems(10)">10</a>
                                            </li>
                                            <li class="page-item" [ngClass]="{active:showItemActive == 50}">
                                                <a class="page-link" (click)="saveActiveItems(50)">50</a>
                                            </li>
                                            <li class="page-item" [ngClass]="{active:showItemActive == 100}">
                                                <a class="page-link" (click)="saveActiveItems(100)">100</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="commonFun.getTabAccess(68)" class="tab-pane tabs-animation fade" id="tab-content-1"
                        role="tabpanel">
                        <div class="main-card mb-3 card">
                            <div class="card-body" style="overflow-x: auto;">
                                <table class="table table-hover table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Sr. No</th>
                                            <th>{{'stateTCName' | translate}}</th>
                                            <th>{{'stateTCDesc' | translate}}</th>
                                            <th *ngIf="commonFun.getFeatureAccess(97)">{{'stateTCStatus' | translate}}
                                            </th>
                                            <th
                                                *ngIf="commonFun.getFeatureAccess(98) || commonFun.getFeatureAccess(99) || commonFun.getFeatureAccess(101)">
                                                {{'stateTCAction' | translate}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngIf="!userDataDisable||userDataDisable.length==0">
                                            <td colspan="5" style="text-align: center;">No Data Found</td>
                                        </tr>
                                        <tr *ngFor="let data of userDataDisable;index as i">
                                            <td>{{((pagerDisable.currentPage-1) * showItemDisable)+(i+1)}}</td>
                                            <td>{{data?.name}}</td>
                                            <td>{{data?.desc}}</td>
                                            <td *ngIf="commonFun.getFeatureAccess(97)">
                                                <label class="switch" (change)="status(data.uniqueId,'enable')">
                                                    <input type="checkbox">
                                                    <span class="slider round"></span>
                                                </label>
                                            </td>
                                            <td
                                                *ngIf="commonFun.getFeatureAccess(98) || commonFun.getFeatureAccess(99) || commonFun.getFeatureAccess(101)">
                                                <a *ngIf="commonFun.getFeatureAccess(98)"
                                                    (click)="userAction('view',data?.uniqueId)"><i
                                                        class="pe-7s-look btn-icon-wrapper"> </i></a>&nbsp; <a
                                                    *ngIf="commonFun.getFeatureAccess(99)"
                                                    (click)="userAction('edit',data?.uniqueId)"><i
                                                        class="pe-7s-note btn-icon-wrapper"> </i></a>&nbsp; <a
                                                    *ngIf="commonFun.getFeatureAccess(101)" class="danger p-0"
                                                    (click)="status(data?.uniqueId,'delete')" title="Delete">
                                                    <i class="fa fa-trash font-medium-3 mr-2"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <ul class="pagination mt-5" *ngIf="disable_count > 10">
                                            <li class="page-item" [ngClass]="{disabled:pagerDisable.currentPage === 1}">
                                                <a class="page-link" (click)="setPageDisble(1)">First</a>
                                            </li>
                                            <li class="page-item" [ngClass]="{disabled:pagerDisable.currentPage === 1}">
                                                <a class="page-link"
                                                    (click)="setPageDisble(pagerDisable.currentPage - 1)">Previous</a>
                                            </li>
                                            <li class="page-item" *ngFor="let page of pager.pages"
                                                [ngClass]="{active:pagerDisable.currentPage === page}">
                                                <a class="page-link" (click)="setPageDisble(page)">{{page}}</a>
                                            </li>
                                            <li class="page-item"
                                                [ngClass]="{disabled:pagerDisable.currentPage === pagerDisable.totalPages}">
                                                <a class="page-link"
                                                    (click)="setPageDisble(pagerDisable.currentPage + 1)">Next</a>
                                            </li>
                                            <li class="page-item"
                                                [ngClass]="{disabled:pagerDisable.currentPage === pagerDisable.totalPages}">
                                                <a class="page-link"
                                                    (click)="setPageDisble(pagerDisable.totalPages)">Last</a>
                                            </li>
                                        </ul>
                                        <label *ngIf="disable_count">Total Record- {{disable_count}}, Total Pages-
                                            {{pagenDisable.totalPage}}</label>
                                    </div>
                                    <div class="col-lg-6">
                                        <ul class="pagination mt-5 pull-right" *ngIf="disable_count>10">
                                            <li class="page-item" [ngClass]="{active:showItemDisable == 10}">
                                                <a class="page-link" (click)="saveDisableItems(10)">10</a>
                                            </li>
                                            <li class="page-item" [ngClass]="{active:showItemDisable == 50}">
                                                <a class="page-link" (click)="saveDisableItems(50)">50</a>
                                            </li>
                                            <li class="page-item" [ngClass]="{active:showItemDisable == 100}">
                                                <a class="page-link" (click)="saveDisableItems(100)">100</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- User profile div start from here-->
    </div>
</div>
<div class="app-drawer-overlay d-none animated fadeIn"></div>
<div class="modal fade" role="dialog" style="margin-top:82px" id="addRecord">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{'stateAddMdlTitle' | translate}}</h5>
                <button type="button" data-dismiss="modal" class="btn btn-secondary">
                    <span>&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="addState" (ngSubmit)="save()">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label><strong>{{'stateAddMdlName' | translate}}<span
                                                class='red'>*</span></strong></label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="btn btn-secondary">
                                                <i class="fa fa-user"></i>
                                            </span>
                                        </div>
                                        <input type="text" class="form-control" formControlName="name"
                                            placeholder="Enter Name"
                                            [ngClass]="{'red-border-class': regisSubimitted && fr.name.errors}">
                                        <!-- <span class="error" *ngIf="regisSubimitted && fr.name.errors">Enter Name</span> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label><strong>{{'stateAddMdlDesc' | translate}}</strong></label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="btn btn-secondary">
                                                <i class="fa fa-comment"></i>
                                            </span>
                                        </div>
                                        <input type="text" class="form-control" formControlName="description"
                                            placeholder="Description">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-2">
                                <button type="submit" class="btn btn-secondary active">{{'stateAddMdlBtnAdd' |
                                    translate}}</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" role="dialog" style="margin-top:82px" id="viewRecord">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{'usrTyViewMdlTitle' | translate}}</h5>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">
                    <span>&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="viewState">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label><strong>{{'stateViewMdlName' | translate}}<span
                                                class='red'>*</span></strong></label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="btn btn-secondary">
                                                <i class="fa fa-user"></i>
                                            </span>
                                        </div>
                                        <input type="text" class="form-control" disabled formControlName="name">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label><strong>{{'stateViewMdlDesc' | translate}}</strong></label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="btn btn-secondary">
                                                <i class="fa fa-comment"></i>
                                            </span>
                                        </div>
                                        <input type="text" class="form-control" disabled formControlName="description">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" role="dialog" style="margin-top:82px" id="editRecord">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{'usrTyEditMdlTitle' | translate}}</h5>
                <button type="button" data-dismiss="modal" class="btn btn-secondary">
                    <span>&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="editState" (ngSubmit)="updateStateType()">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label><strong>{{'stateEditMdlName' | translate}}<span
                                                class='red'>*</span></strong></label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="btn btn-secondary">
                                                <i class="fa fa-user"></i>
                                            </span>
                                        </div>
                                        <input type="text" class="form-control" formControlName="name"
                                            placeholder="Enter Name"
                                            [ngClass]="{'red-border-class': regisSubimitted && fr.name.errors}">
                                        <!-- <span class="error" *ngIf="regisSubimitted && fr.name.errors">Enter Name</span> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label><strong>{{'stateEditMdlDesc' | translate}}</strong></label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="btn btn-secondary">
                                                <i class="fa fa-comment"></i>
                                            </span>
                                        </div>
                                        <input type="text" class="form-control" formControlName="description">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-2">
                                <button type="submit" class="btn btn-secondary active">{{'stateEditMdlBtn' |
                                    translate}}</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectLangComponent } from './select-lang/select-lang.component';



@NgModule({
  declarations: [SelectLangComponent],
  imports: [
    CommonModule
  ],
  exports: [
    SelectLangComponent
  ]
})
export class SelectLngModule { }
